import {
  Box,
  Container,
  Grid,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setFeedBack } from "../../features/slice/vendorSlice";
//ICONS
import CloseIcon from "@mui/icons-material/Close";
import dayjs from "dayjs";
import { Padding } from "@mui/icons-material";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: { sm: `calc(100% - ${drawerWidth}px)` },

  maxHeight: "89%",
  width: "50%",
  // eight:"1000px",
  border: "2px solid white",
  boxShadow: 24,
  padding:12,
  background: "white",
  overflow: "scroll",
};
function ViewFeedBack() {
  const { feedBack } = useSelector((state) => state.vendor);
  const dispatch = useDispatch();
  return (
    <>
      <Modal
        open={feedBack !== null}
        onClose={() => dispatch(setFeedBack(null))}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box style={style}>
          {" "}
          <Box>
            <IconButton
              sx={{ float: "right" }}
              onClick={() => dispatch(setFeedBack(null))}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Container>
            <Box
              display={"flex"}
              sx={{ py: 3 }}
              maxHeight={"1%"}
              justifyContent={"space-between"}
            >
             
              <Typography variant="h2" component={"div"}>
                {feedBack?.vendor.company_name}
              </Typography>

              <Box marginRight={3}>
                {dayjs(feedBack?.date_time).format("MM/DD/YYYY")}
              </Box>
            </Box>
            <Box
              container
              // justifyContent={"center"}
              height={"100%"}
              // alignItems={"center"}
              // rowSpacing={0}
              marginTop={5}
            >
              <Typography fontFamily={"revert"} fontStyle={"oblique"} variant="h3" component={"span"} marginBottom={3}>
                Feedback
              </Typography>
              <Box display={'inline'} sx={{float:'right'}} color={feedBack?.status=="completed"?"green":(feedBack?.status=="pending")?"blue":"red"}>{feedBack?.status}</Box>
              <Box
                sx={{
                  overflow: "scroll",
                  mt:3,
                  pb:2
                }}
              >
                <Typography variant="h5">{feedBack?.feedBack}</Typography>
              </Box>
            </Box>
          </Container>
        </Box>
      </Modal>
    </>
  );
}

export default ViewFeedBack;
