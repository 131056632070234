import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React from "react";
import { NavLink, useParams } from "react-router-dom";
import AddCard from "./addcard";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { setBreadCrumb } from "../../features/slice/otherSlice";
import { useListCardsQuery } from "../../features/api/cardapi";
import { useState } from "react";
//ICON
import VisibilityIcon from "@mui/icons-material/Visibility";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import ModeOutlinedIcon from "@mui/icons-material/ModeOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { tokens } from "../../global/theme";
export default function PaymentMethod() {
  console.log(window.location.pathname);
  const theme = useTheme();
  const parms = useParams();
  const [open, setOpen] = React.useState(
    window.location.pathname !== "/payment_management" ? true : false
  );
  const colors = tokens(theme.palette.mode);
  const [cards, setCards] = useState([]);
  const dispatch = useDispatch();
  //APIS
  const { data, isLoading: cardsLoading } = useListCardsQuery();
  const columns = [
    {
      field: "brand",
      headerName: "Payment Method",
      headerAlign: "center",
      align: "center",
      flex: 1,
      maxWidth: 300,
      renderCell:()=>"Stripe"
    },
    {
      field: "name",
      headerName: "Card Info",
      headerAlign: "center",
      align: "center",
      flex: 1,
      maxWidth: 300,
    },
    {
      field: "cvc_checks",
      headerName: "Status",
      headerAlign: "center",
      align: "center",
      flex: 1,
      maxWidth: 300,
      renderCell:()=>"Active"

    },
    {
      field: "Actions",
      headerAlign: "center",
      align: "center",
      sortable: false,
      disableColumnSelector: true,
      disableColumnMenu: true,
      disableColumnFilter: true,
      flex: 1,
      minWidth: 150,
      renderCell: (value) => {
        return (
          <>
            <Tooltip title="View">
              <IconButton
                sx={{
                  color: colors.blueAccent[500],
                }}
                // onClick={() => setView(value?.row)}
              >
                <VisibilityIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Edit">
              <IconButton
                sx={{
                  color: "#ef9834",
                }}
                // onClick={() => {
                //   dispatch(setDetails(value.row));
                //   navigate(`/subscription/edit/package/${value?.row?._id}`);
                // }}
              >
                <ModeOutlinedIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete">
              <IconButton
                color="error"
                // onClick={() => handleDelete(value?.row?._id)}
                // onClick={() => setdId(value?.row?._id)}
              >
                <DeleteOutlineOutlinedIcon />
              </IconButton>
            </Tooltip>
          </>
        );
      },
    },
  ];
  useEffect(() => {
    dispatch(
      setBreadCrumb([
        {
          name: "PAYMENT METHOD",
          path: "/payment_management",
        },
      ])
    );
  }, [dispatch, window.location]);
  useEffect(() => {
    let list = [];
    if (data != undefined) {
      data?.data?.forEach((c) =>
        list.push(...c.details)
      );
      setCards(list);
    }
  }, [data]);
  console.log("CARDS", cards);
  return (
    <>
      <Box
        sx={{
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "5px",
        }}
      >
        <Box
          display="flex"
          flexWrap={"wrap"}
          alignItems={"center"}
          justifyContent={"space-between"}
          paddingX={1}
          paddingY={1}
        >
          <Box>
            <Button
              variant="contained"
              sx={{
                color: "#ffff",
              }}
              color="primary"
              onClick={() => {
                setOpen(true);
              }}
            >
              Add New Card
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            height: "100%",
            maxHeight: "90%",
          }}
        >
          <DataGrid
            disableSelectionOnClick
            rows={cards ? cards : []}
            columns={columns}
          />
        </Box>
      </Box>
      <AddCard open={open} setOpen={setOpen} id={parms.company_name} />
    </>
  );
}
