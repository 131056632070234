import axios from "axios";
import { mainApi } from "./mainApi";
import { useSelector } from "react-redux";
import { thunk, getState } from "redux-thunk";
export const menuApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    //GET MENU LIST BY VENDOR_ID
    GetMenuListByVendor: builder.query({
      query: (vendor_id) => `getmenu?vendor_id=${vendor_id}`,
      providesTags: ["menu"],
    }),
    //UPLOAD MENU
    UploadMenu: builder.mutation({
      queryFn: async ({ formData, setProgress }) => {
        let token = JSON.parse(
          JSON.parse(localStorage.getItem("persist:root")).auth
        ).token;
        console.log("TOKEN", token);
        const result = await axios.post(
          process.env.REACT_APP_MAIN_URL + "/uploadmenu",
          formData,
          {
            headers: {
              Authorization: "Bearer " + token,
            },
            onUploadProgress: (upload) => {
              let uploadloadProgress = Math.round(
                (100 * upload.loaded) / upload.total
              );
              console.log("uploadloadProgress", uploadloadProgress);
              setProgress(uploadloadProgress);
            },
          }
        );
        return result;
      },

      invalidatesTags: ["menu"],
    }),
    //QRCODE DOWNLOAD
    DownloadQRCode: builder.mutation({
      query: (data) => ({
        url: "qrcode",
        method: "POST",
        body: data,
        responseHandler: async (res) => {
          if (res.status === 200) {
            return await res.blob();
          } else {
            return await res.text();
          }
        },
      }),
    }),
    //MENU DOWNLOAD
    DownloadMenu: builder.mutation({
      query: (data) => ({
        url: "downloadmenu",
        method: "POST",
        body: data,
        responseHandler: async (res) => {
          if (res.status === 200) {
            return await res.blob();
          } else {
            return await res.text();
          }
        },
      }),
    }),
    //DELETE MENU
    DeleteMenu: builder.mutation({
      query: (data) => ({
        url: "deletemenu",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["menu"],
    }),
  }),
});
export const {
  useGetMenuListByVendorQuery,
  useUploadMenuMutation,
  useDownloadQRCodeMutation,
  useDownloadMenuMutation,
  useDeleteMenuMutation,
} = menuApi;
