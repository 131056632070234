import { Box, Container, Grid, TextField, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import React from "react";
import * as yup from "yup";
import InputMask from "react-input-mask";
import { useState } from "react";

//ICONS
import LanguageIcon from "@mui/icons-material/Language";
import PersonIcon from "@mui/icons-material/Person";
import LinkIcon from "@mui/icons-material/Link";
import PlaceIcon from "@mui/icons-material/Place";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import HouseIcon from "@mui/icons-material/House";
import BusinessIcon from "@mui/icons-material/Business";
import SmartphoneIcon from "@mui/icons-material/Smartphone";
import EmailIcon from "@mui/icons-material/Email";
import { LoadingButton } from "@mui/lab";
import MessageIcon from "@mui/icons-material/Message";

import { PatternFormat } from "react-number-format";
import { useCreatePartnerMutation } from "../../features/api/partnerLeads";
import { toast } from "react-toastify";
import toastError from "../../components/toasts/toast";

export const OnlineForm = () => {
  const [fields] = useState([
    { key: "firstname", icon: <PersonIcon sx={{ color: "red" }} /> },
    { key: "lastname", icon: <PersonIcon sx={{ color: "red" }} /> },
    { key: "company_name", icon: <BusinessIcon sx={{ color: "red" }} /> },
    { key: "email", icon: <EmailIcon sx={{ color: "red" }} /> },
    { key: "mobile_number", icon: <SmartphoneIcon sx={{ color: "red" }} /> },
    {
      key: "nature_of_business",
      icon: <BusinessCenterIcon sx={{ color: "red" }} />,
    },
    // { key: "place_name", icon: <PlaceIcon sx={{ color: "red" }} /> },
    { key: "address", icon: <PlaceIcon sx={{ color: "red" }} /> },
    { key: "area", icon: <PlaceIcon sx={{ color: "red" }} /> },
    { key: "state", icon: <PlaceIcon sx={{ color: "red" }} /> },
    { key: "city", icon: <PlaceIcon sx={{ color: "red" }} /> },
    { key: "zip_code", icon: <PlaceIcon sx={{ color: "red" }} /> },
    { key: "web_site", icon: <LinkIcon sx={{ color: "red" }} /> },
    { key: "message", icon: <MessageIcon sx={{ color: "red" }} /> },
  ]);
  //APIS
  const [CreatePartner, { isLoading }] = useCreatePartnerMutation();
  const validationSchema = yup.object().shape({
    firstname: yup
      .string("Enter your firstname")
      .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ")
      .required("Firstname is required"),
    lastname: yup
      .string("Enter your lastname")
      .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ")
      .required("Lastname is required"),
    company_name: yup
      .string("Enter your company name")
      .required("Company name is required"),
    nature_of_business: yup
      .string("Enter your company name")
      .required("Nature of business is required"),
    email: yup
      .string("Enter your email")
      .email("Enter a valid email")
      .required("Email is required"),
    city: yup
      .string("Enter your city")
      .required("City is required")
      .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
    area: yup.string("Enter your area").required("Area is required"),
    // subscription_type: yup
    //   .string("Select subscription type")
    //   .required("Subscription type is required"),
    mobile_number: yup
      .string("Enter your phone number")
      .required("Phone number is required")
      .matches(/^[0-9,+]+$/, "Must be only digits")
      .min(12, "Enter a Valid Phone Number")
      .max(12, "Enter a valid Phone Number"),
    country: yup.string("Select your Country").required("Country is required"),
    state: yup
      .string("Select your State")
      .required("State is required")
      .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
    // twilio_number: yup
    //   .string("Enter your twilio number")
    //   .matches(/^[0-9,+]+$/, "Must be only digits")
    //   .required("Twilio number is required")
    //   .min(12, "Enter Valid twilio Number")
    //   .max(12, "Enter valid twilio Number"),
    address: yup
      .string("Enter your address")
      // .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ")
      .required("Address is required"),
    zip_code: yup
      .string()
      .required("Zipcode is required")
      .matches(/^[0-9]+$/, "Must be only digits")
      .min(6, "Enter Valid ZipCode Number")
      .max(10, "Enter valid ZipCode Number"),
    // subscription_type: yup.string().required("Subscription Type is required"),
    web_site: yup
      .string()
      .matches(
        /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
        "Enter correct url!"
      ),
    message: yup.string().required("Message is Required"),
    // image: yup
    //   .mixed(),
    // .test("fileSize", "The file is too large", (value) => {
    //   return value && value[0].sienter <= 2000000;
    // })
    // .test("type", "Only the following formats are accepted: .jpeg, .jpg, .bmp, .pdf and .doc", (value) => {
    //   return value && (
    //     value[0].type === "image/*"
    //   );
    // }),
  });

  return (
    <>
      <Container
        maxWidth="sm"
        sx={{
          bgcolor: "white",
          height: "100%",
          overflow: "scroll",
          paddingBottom: "2%",
        }}
      >
        <Grid
          width={"100%"}
          padding={4}
          container
          spacing={3}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Grid item>
            <Typography variant="h3" component={"span"}>
              Partner with Us
            </Typography>
          </Grid>
          <Grid item>
            <LanguageIcon sx={{ fontSize: "250%", color: "red" }} />
          </Grid>
        </Grid>
        <Box>
          <Formik
            initialValues={{
              firstname: "",
              lastname: "",
              company_name: "",
              email: "",
              mobile_number: "",
              nature_of_business: "",
              // place_name: "",
              address: "",
              area: "",
              state: "",
              city: "",
              country: "US",
              zip_code: "",
              web_site: "",
              message: "",
            }}
            validationSchema={validationSchema}
            validateOnChange={true}
            onSubmit={(values) => {
              console.log(values);
              CreatePartner(values)
                .unwrap()
                .then((res) =>
                  toast.success("Your form is submited successfully")
                )
                .catch((err) =>
                  toastError(
                    err?.data?.error
                      ? err?.data?.error
                      : "Internal server error"
                  )
                );
            }}
          >
            {({
              errors,
              touched,
              handleChange,
              setFieldValue,
              getFieldMeta,
              setFieldTouched,
              values,
            }) => (
              <Form>
                <Grid container rowSpacing={5}>
                  {fields?.map((field, i) => {
                    let item = field?.key;
                    let icon = field?.icon;
                    if (item === "mobile_number") {
                      return (
                        <Grid item sm={12} key={i}>
                          <PatternFormat
                            sx={{ letterSpacing: "10" }}
                            name={item}
                            key={i}
                            label={item.toUpperCase()}
                            style={{
                              width: "100%",
                              height: "100%",

                              border: "none",
                            }}
                            error={touched[item] && Boolean(errors[item])}
                            helperText={touched[item] && errors[item]}
                            width={"100%"}
                            format={
                              values[item] == "" || values[item] == "+1"
                                ? " ### ### ####"
                                : " (###)-###-####"
                            }
                            allowEmptyFormatting
                            mask=" "
                            customInput={TextField}
                            onChange={(e) =>
                              setFieldValue(
                                item,
                                ("+1" + e.target.value).replaceAll(
                                  /([^\w+]+|\s+)/g,
                                  ""
                                )
                              )
                            }
                            InputProps={{
                              startAdornment: (
                                <>
                                  {" "}
                                  <span>{icon}</span>+1
                                </>
                              ),
                              inputProps: { style: { letterSpacing: "4px" } },
                            }}
                          />
                        </Grid>
                      );
                    } else {
                      return (
                        <>
                          <Grid item xs={12} sm={12} md={12} key={i}>
                            <TextField
                              name={item}
                              key={i}
                              onChange={handleChange}
                              // required
                              placeholder={
                                " " + item.toUpperCase().replaceAll("_", " ")
                              }
                              getFieldMeta
                              fullWidth
                              multiline={item == "message" ? true : false}
                              error={touched[item] && Boolean(errors[item])}
                              helperText={touched[item] && errors[item]}
                              // defaultValue={values[item]}
                              type={
                                item === "message"
                                  ? "textArea"
                                  : item == "zip_code"
                                  ? "number"
                                  : null
                              }
                              id="outlined-basic"
                              label={item.toUpperCase().replace(/_/g, " ")}
                              variant="outlined"
                              onKeyPress={(evt) => {
                                if (
                                  item == "zip_code" &&
                                  ((evt.which != 8 &&
                                    evt.which != 0 &&
                                    evt.which < 48) ||
                                    evt.which > 57 ||
                                    evt.target.value.length == 10)
                                ) {
                                  evt.preventDefault();
                                }
                                if (
                                  [
                                    "firstname",
                                    "lastname",
                                    "state",
                                    "city",
                                  ].includes(item) &&
                                  !(
                                    (evt.which != 8 &&
                                      evt.which != 0 &&
                                      evt.which < 48) ||
                                    evt.which > 57
                                  )
                                ) {
                                  evt.preventDefault();
                                }
                              }}
                              InputProps={{
                                inputProps: { step: "any" },
                                startAdornment: (
                                  <span
                                    key={i}
                                    style={{ paddingRight: "1rem" }}
                                  >
                                    {icon}
                                  </span>
                                ),

                                endAdornment: null,
                              }}
                            />
                          </Grid>
                        </>
                      );
                    }
                  })}
                </Grid>
                <LoadingButton
                  style={{ marginTop: "10px" }}
                  loading={isLoading}
                  sx={{
                    width: "100%",
                    color: "#ffff",
                    height: "50px",
                  }}
                  loadingPosition="center"
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  <span>SUBMIT</span>
                </LoadingButton>
              </Form>
            )}
          </Formik>
        </Box>
      </Container>
    </>
  );
};
