import React from "react";
import { toast } from "react-toastify";

function toastError(err) {
  if (!(typeof(err)==="string")) {
    for(let i=0;i<=err.length;i++){
        console.log(err[i]);
        return  toast.error((err[i]).replaceAll(/([^\w,]+|\s+)/g," "))
    }

  } else {
    console.log("ERRORJKL",err);
    return toast.error(err.replaceAll(/([^\w,]+|\s+)/g," "));
  }
}

export default toastError;
