import { mainApi } from "./mainApi";

export const TwilioApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    //Vendor List
    TwilioView: builder.query({
      query: (id) => `/twilioconfig/${id}`,
      providesTags: ["twilio"],
    }),
    //ADD TWILIO CONFIG
    AddTwilioConfig: builder.mutation({
      query: (twilioData) => ({
        url: `/twilioconfig/createforadmin`,
        method: "POST",
        body: twilioData,
      }),
      invalidatesTags: ["twilio,user"],
    }),
  }),
});

export const { useAddTwilioConfigMutation, useTwilioViewQuery } = TwilioApi;
