import {
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  NativeSelect,
  Select,
  Stack,
  Tooltip,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import dayjs from "dayjs";
import React, { useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import ModeOutlinedIcon from "@mui/icons-material/ModeOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import SubdirectoryArrowRightIcon from "@mui/icons-material/SubdirectoryArrowRight";
import { useTheme } from "@emotion/react";
import { tokens } from "../../global/theme";
import { setBreadCrumb } from "../../features/slice/otherSlice";
import { useVendorFeedbackByIdQuery } from "../../features/api/vendorApi";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  useAllFeedBackQuery,
  useDeleteFeedBackMutation,
  useFeedbackStatusChangeMutation,
} from "../../features/api/feedback";
import { toast } from "react-toastify";
import ViewFeedBack from "./ViewFeedBack";
import { setFeedBack } from "../../features/slice/vendorSlice";
import DeleteDialogue from "../../components/deletepopup/delete";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/loader/Loader";

function FeedBackLists() {
  const [feedBacks, setFeedBacks] = useState();
  const [dId, setdId] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  //API
  const { data, isLoading } = useAllFeedBackQuery();
  const [feedbackStatusChange, { isLoading: floading }] =
    useFeedbackStatusChangeMutation();
  const [DeleteFeedBack, { isLoading: deleteLoading }] =
    useDeleteFeedBackMutation();
  console.log("HD", data?.data);

  //DELETE FEEDBACK FUNCTION
  const handleDelete = (id) => {
    DeleteFeedBack(id)
      .unwrap()
      .then((res) => {
        toast.success("FeedBack Deleted Successfully");
        setdId(null);
      })
      .catch((e) => toast("Can not delete this moment"));
  };

  //Api data to state
  useEffect(() => {
    if (data !== undefined) {
      setFeedBacks(data?.data);
    }
  }, [data]);
  //handleBreadCrub
  useEffect(() => {
    dispatch(
      setBreadCrumb([
        {
          name: "Feedback",
          path: "/feedback",
        },
      ])
    );
  }, [dispatch, window.location]);
  const columns = [
    {
      field: "unique_id",
      headerName: "VENDOR ID",
      align: "center",
      headerAlign: "center",
      flex: 1,
      minWidth: 100,

      valueGetter: (value) => `${value.row.vendor.unique_id}`,
    },
    {
      field: "firstname",
      headerName: "NAME",
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 100,
      valueGetter: (value) => `${value.row.vendor.firstname}`,
      // sortComparator: (v1, v2) =>
      // v1.firstname.localeCompare(v2.firstname),
    },
    {
      field: "vendor",
      headerName: "COMPANY NAME",
      headerAlign: "center",
      align: "center",
      minWidth: 150,
      valueGetter: (values) => `${values.value.company_name}`,
    },
    {
      field: "feedBack",
      headerName: "FEEDBACK MESSAGE",
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 250,
      // flex: 1,
    },
    {
      field: "",
      headerName: "MOBILE NUMBER",
      align: "center",
      headerAlign: "center",
      flex: 1,
      minWidth: 200,
      valueGetter: (value) => ` ${value?.row?.vendor.phone}`,
    },
    {
      field: "date_time",
      headerName: "DATE & TIME",
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 150,
      valueGetter: (value) => dayjs(value?.row?.date_time).format("MM/DD/YYYY"),
    },
    {
      field: "status",
      headerName: "STATUS",
      align: "center",
      headerAlign: "center",
      flex: 1,
      minWidth: 150,
      renderCell: (value) => {
        return (
          <>
            <FormControl fullWidth>
              <NativeSelect
                defaultValue={value?.row?.status}
                value={value?.row?.status}
                onChange={(e) =>
                  feedbackStatusChange({
                    id: value?.row?._id,
                    data: { status: e.target.value },
                  })
                    .unwrap()
                    .then((res) => toast.success(`Status Changed`))
                    .catch((e) => toast.error("Status Not Changed This Moment"))
                }
                inputProps={{
                  name: "age",
                  id: "uncontrolled-native",
                }}
              >
                <option value={"Completed"}>Completed</option>
                <option value={"Pending"}>Pending</option>
                <option value={"Take Action"}>Take Action</option>
              </NativeSelect>
            </FormControl>
          </>
        );
      },
    },
    {
      field: "ACTIONS",
      headerAlign: "center",
      align: "center",
      sortable: false,
      disableColumnSelector: true,
      disableColumnMenu: true,
      disableColumnFilter: true,
      flex: 1,
      minWidth: 150,
      renderCell: (value) => {
        return (
          <>
            <Tooltip title="View">
              <IconButton
                sx={{
                  color: colors.blueAccent[500],
                }}
                onClick={() => dispatch(setFeedBack(value?.row))}
                // onClick={()=>navigate(`/feedback/${value?.row.vendor_id}`)}
              >
                <VisibilityIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="All feedback">
              <IconButton
                sx={{
                  color: colors.blueAccent[500],
                }}
                // onClick={() => dispatch(setFeedBack(value?.row))}
                onClick={() => navigate(`/feedback/${value?.row?.vendor_id}`)}
              >
                <SubdirectoryArrowRightIcon />
              </IconButton>
            </Tooltip>

            <Tooltip title="Delete">
              <IconButton
                color="error"
                // onClick={() => handleDelete(value?.row?._id)}
                onClick={() => setdId(value?.row?._id)}
              >
                <DeleteOutlineOutlinedIcon />
              </IconButton>
            </Tooltip>
          </>
        );
      },
    },
  ];
  return (
    <>
      <DataGrid
        getRowId={(op) => op?._id}
        disableSelectionOnClick
        rows={feedBacks ? feedBacks : []}
        columns={columns}
        components={{
          Toolbar: GridToolbar,
          NoRowsOverlay: () => {
            // if (!isLoading && data == undefined) {
              return (
                <Stack
                  height="100%"
                  alignItems="center"
                  justifyContent="center"
                >
                  { isLoading ? (
                  <Loader /> 
                  ) :!isLoading && data == undefined ? (
                     " Something went wrong"
                  ) : !data?.data[0] ? (
                    " No rows"
                  ) : (
                    null
                  )}
                  {/*Something went wrong*/}
                </Stack>
              );
            // }
            // if (isLoading) {
            //   return (
            //     <Stack
            //       height="100%"
            //       alignItems="center"
            //       justifyContent="center"
            //     >
            //       <Loader />
            //     </Stack>
            //   );
            // }
            // if (!data?.data[0]) {
            //   return (
            //     <Stack
            //       height="100%"
            //       alignItems="center"
            //       justifyContent="center"
            //     >
            //       No rows
            //     </Stack>
            //   );
            // }
          },
        }}
      />{" "}
      <ViewFeedBack />
      {dId && (
        <DeleteDialogue
          dId={dId}
          setdId={setdId}
          handleDelete={handleDelete}
          isLoading={deleteLoading}
        />
      )}
    </>
  );
}

export default FeedBackLists;
