import React, { createRef, useEffect, useState } from "react"
import { Stack, Box, useTheme, Typography } from "@mui/material"
import { useDispatch } from "react-redux"
import { useLocation, useParams } from "react-router-dom"
import dayjs from "dayjs"
import Loader from "../../components/loader/Loader"
import {
    FeedBackVendor,
    VendorFeedBacksChat,
} from "../../components/messages/Messages"
import { tokens } from "../../global/theme"
//Api
import { useVendorAllFeedBackQuery } from "../../features/api/feedback"
import { setBreadCrumb } from "../../features/slice/otherSlice"
import { useVendorFeedbackByIdQuery } from "../../features/api/vendorApi"

const FeedBackMessage = ({ colors }) => {
    const { vendorId } = useParams()
    const dispatch = useDispatch()
    const location = useLocation()
    const messageEl = createRef()
    const [vendorFeedBackData, setVendorFeedBackData] = useState([])
    const [vendorName, setVendorName] = useState("")

    //Feedback by vendor ID
    const { data: feedbackByVendorIdApi, isLoading, isFetching, refetch } = useVendorFeedbackByIdQuery(vendorId)
    let dat = []

    // Focus lastest message automatically
    useEffect(() => {
        if (messageEl.current !== null) {
            const scroll =
                messageEl?.current.scrollHeight -
                messageEl?.current.clientHeight
            messageEl?.current.scrollTo(0, scroll)
        }
    }, [vendorFeedBackData, messageEl])

    //handleBreadCrub
    useEffect(() => {
        if (vendorFeedBackData.length >= 1) {
            dispatch(setBreadCrumb([{
                name: "Feedback",
                path: "/feedback"
            },
            {
                name: vendorName ?? "helo",
                pathname: location.pathname
            }
            ]))
        } else {
            dispatch(setBreadCrumb([{
                name: "Feedback",
                path: "/feedback"
            }
            ]))
        }
    }, [vendorName, vendorFeedBackData, dispatch, location])

    //Api data to state
    useEffect(() => {
        if (feedbackByVendorIdApi !== undefined) {
            setVendorFeedBackData(feedbackByVendorIdApi?.data[0]?.feedback)
            setVendorName(feedbackByVendorIdApi?.data[0]?.company_name)
        }
    }, [feedbackByVendorIdApi, vendorId])

    //Refecth vendor Id Change
    useEffect(() => {
        refetch(vendorId)
    }, [vendorId, refetch])

    return (
        <>
            {
                isLoading === true || isFetching === true ? <Loader /> : <>
                    {
                        vendorFeedBackData.length >= 1 ? <>
                            {
                                vendorFeedBackData?.map((item, index) => {
                                    if (
                                        dat?.includes(
                                            dayjs(item?.feedBack_date).format(
                                                "MM/DD/YYYY"
                                            )
                                        )
                                    ) {
                                        return (
                                            <VendorFeedBacksChat
                                                demo={messageEl}
                                                key={index}
                                                data={item}
                                                colors={colors}
                                                divide={false}
                                                align={"end"}
                                            />
                                        )
                                    } else {
                                        dat.push(
                                            dayjs(item?.feedBack_date).format(
                                                "MM/DD/YYYY"
                                            )
                                        )
                                        return (
                                            <VendorFeedBacksChat
                                                // demo={messageEl}
                                                key={index}
                                                data={item}
                                                colors={colors}
                                                divide={true}
                                                align={'end'}
                                            />
                                        )
                                    }
                                })
                            }</>
                            : <Box sx={{
                                display: "flex",
                                height: "100%",
                                justifyContent: "center",
                                alignItems: "center"
                            }}>
                                <Typography sx={{
                                }}>Click vendor list to show feedback</Typography>
                            </Box>
                    }
                </>
            }
        </>
    )
}


const FeedBack = () => {
    const { vendorId } = useParams()
    const messageEl = createRef()
    const dispatch = useDispatch()
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)
    const [vendorList, setVendorList] = useState([])

    //Vendor List Api
    const {
        data: vendorApiData,
        isLoading: vendorLoading,
        isFetching: vendorFetching,
    } = useVendorAllFeedBackQuery()

    //Vendor List
    useEffect(() => {
        if (vendorApiData !== undefined) {
            setVendorList(vendorApiData.data)
            console.log(vendorApiData, "vendorApiData")
        }
    }, [vendorApiData])



    //Breadcrumb
    useEffect(() => {
        dispatch(setBreadCrumb([{
            name: "Feedback",
            path: "/feedback"
        }]))
    }, [vendorId, dispatch])

    return (
        <>
            <Stack
                className="main"
                direction={"row"}
                sx={{
                    height: "100%",
                    width: "100%",
                }}>
                {/* Chat page Customer List */}
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        // width: "20%",
                        minWidth: "110px",
                        maxWidth: "220px",
                        // width: "150px",
                    }}>
                    <Box
                        sx={{
                            // height: "60px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}>
                        Vendor list
                    </Box>
                    <Stack
                        sx={{
                            height: "calc(100% - 60px)",
                            overflowY: "scroll",
                            // width: "100%",
                            display: "flex",
                            pt: 1,
                            flexDirection: "column",
                            gap: "10px",
                            maxWidth: "220px",
                        }}>
                        {vendorLoading === true || vendorFetching === true ? (
                            <Loader />
                        ) : (
                            <>
                                {vendorList?.map((item, index) => {
                                    return (
                                        <FeedBackVendor
                                            colors={colors}
                                            data={item}
                                            key={index}
                                        />
                                    )
                                })}
                            </>
                        )}
                    </Stack>
                </Box>
                {/* Feedback page Chat List */}
                <Box
                    sx={{
                        display: "flex",
                        height: "100%",
                        flexDirection: "column",
                        width: "100%",
                    }}>
                    <Box
                        sx={{
                            // height: "60px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}>
                        Vendor Feedback
                    </Box>
                    <Box
                        ref={messageEl}
                        className="chat_inset"
                        sx={{
                            height: "calc(100% - 30px)",
                            m: 1,
                            overflowY: "scroll",
                            scrollbarWidth: "none",
                            padding: "10px",
                            // display: "flex",
                            gap: "10px",
                            // justifyContent: "flex-end",
                            borderRadius: "10px",
                            background: colors.grey[900],
                            // flexDirection: "column",
                            zIndex: 1,
                        }}>

                        {
                            vendorId ? <>
                                <FeedBackMessage colors={colors} />
                            </> : <Box sx={{
                                display: "flex",
                                height: "100%",
                                justifyContent: "center",
                                alignItems: "center"
                            }}>
                                <Typography sx={{
                                }}>Click vendor list to show feedback</Typography>
                            </Box>
                        }
                    </Box>
                </Box>
            </Stack>
        </>
    )
}

export default FeedBack

// import React, { useEffect } from 'react'
// import { Box, Divider, Typography } from '@mui/material'
// import styled from '@emotion/styled'
// import { useDispatch } from 'react-redux'
// import { setBreadCrumb } from '../../features/slice/otherSlice'

// const FeedBackContainer = styled.main`
//     height : 100%;
//     display : flex;
//     flex-direction : column;
// `

// const TopWrapper = styled('main')((props) => ({
//     display: 'flex',
//     flexDirection: 'column',
//     gap: "10px",
// }))

// const Span = styled.span`
//     height : 100%;
//     display : flex;
//     flex-direction : column;
//     gap : 10px;
// `

// const FeedBackBottom = styled.main`
//     height : 100%;
// `

// const FeedBack = () => {
//     const dispatch = useDispatch()

//     useEffect(() => {
//         dispatch(
//             setBreadCrumb([
//                 {
//                     name: "FeedBack",
//                     path: "/feedback",
//                 },
//             ])
//         )
//     }, [])
//     return (
//         <FeedBackContainer>
//             {/* <Typography pt={1} variant='h2' component="h5">Reviews</Typography>
//             <Box pt={1}>
//                 <Box
//                     sx={{
//                         display: "flex",
//                         height: "100%",
//                         flexDirection: { xs: 'column', md: 'row' },
//                         padding: "5px",
//                         gap: '10px',
//                         justifyContent: "space-evenly"
//                     }}
//                 >
//                     <TopWrapper>
//                         <Span>Total Reviews</Span>
//                         <Span>Total Reviews</Span>
//                         <Span>Total Reviews</Span>
//                     </TopWrapper>
//                     <TopWrapper>
//                         <Span>Total Reviews</Span>
//                         <Span>Total Reviews</Span>
//                         <Span>Total Reviews</Span>
//                     </TopWrapper>
//                     <TopWrapper>
//                         <Span>Total Reviews</Span>
//                         <Span>Total Reviews</Span>
//                         <Span>Total Reviews</Span>
//                     </TopWrapper>
//                 </Box>
//                 <Divider />
//                 <FeedBackBottom>

//                 </FeedBackBottom>
//             </Box> */}

//         </FeedBackContainer>
//     )
// }

// export default FeedBack
