import { createSlice } from "@reduxjs/toolkit";

const vendorSlice = createSlice({
  name: "vendors",
  initialState: {
    vendor: null,
    cardDetail: null,
    feedBack: null,
  },
  reducers: {
    setVendor: (state, actions) => {
      state.vendor = actions.payload;
    },
    setCardDetail: (state, action) => {
      state.cardDetail = action.payload;
    },
    setFeedBack: (state, action) => {
      state.feedBack = action.payload;
    },
  },
});

export const { setVendor, setCardDetail, setFeedBack } = vendorSlice.actions;

export default vendorSlice.reducer;
