import { LoadingButton } from "@mui/lab";
import {
  Box,
  Container,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { Form, Formik, useFormik } from "formik";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setBreadCrumb } from "../../features/slice/otherSlice";
import {
  useAddSubsPackageMutation,
  useEditPackageMutation,
  usePackageByIdQuery,
} from "../../features/api/subscription";
import * as yup from "yup";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../components/loader/Loader";
import toastError from "../../components/toasts/toast";

export default function AddPackage() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const pathname = window.location.pathname;
  const navigate = useNavigate();
  const [packageById, setPackageById] = useState(null);
  console.log("packageById", packageById);
  const [initialValue] = useState([
    "subscription_type",
    "subscription_amount",
    "validity",
    "allowed_sms",
    "status",
  ]);

  //API
  const [AddSubsPackage, { isLoading }] = useAddSubsPackageMutation();
  const {
    data: packageDetails,
    isFetching,
    isLoading: packageDetailsLoading,
    isSuccess,
  } = usePackageByIdQuery(id);
  const [EditPackage, { isLoading: editPackageLoading }] =
    useEditPackageMutation();

  //PACKAGE VALIDATION

  const validationSchema = yup.object().shape({
    subscription_type: yup
      .string("Enter your Subscription Type")
      .required(" Subscription Type is required"),
    // .max(15, "Should be Maximum 15 characters"),
    subscription_amount: yup
      .number("Enter Package Prize ")
      // .matches(/[a-zA-Z]/, 'Password can only contain Latin letters.')
      .min(0.5, "Amount Should be Minimum .5$")
      .required("Prize is required"),
    validity: yup
      .number("Enter Validity Days")
      .required("Validity is Required")
      .min(0, "Please Enter Valid Number"),
    allowed_sms: yup
      .number("Enter Number of SMS to be Allowed")
      .required("Allowed SMS is Required")
      .min(0, "Please Enter Valid Number"),
  });

  useEffect(() => {
    if (!id) {
      dispatch(
        setBreadCrumb([
          {
            name: "SUBSCRIBTIONS",
            path: "/subscription",
          },
          {
            name: "ADD PACKAGE",
            path: "/subscription/addpackage",
          },
        ])
      );
    } else {
      dispatch(
        setBreadCrumb([
          {
            name: "EDIT PACKAGE",
            path: pathname,
          },
        ])
      );
    }
  }, [dispatch, window.location]);
  useEffect(() => {
    if (packageDetails !== undefined) {
      setPackageById(packageDetails.data);
    }
  }, [packageDetails, window.location]);
  return (
    <>
      <Container>
        <Typography variant="h3" component="h3" sx={{ mt: 5 }}>
          {pathname == "/subscription/addpackage"
            ? "Add Subscription Package"
            : "Edit Subscription Package"}
        </Typography>

        <Box sx={{ flexGrow: 1 }} style={{ marginTop: "50px" }}>
          {packageById || pathname == "/subscription/addpackage" ? (
            <Formik
              initialValues={{
                subscription_type: packageById?.subscription_type,
                subscription_amount: packageById?(packageById?.subscription_amount / 100):"",
                validity: 30,
                allowed_sms: packageById?.allowed_sms,
                status: packageById?.status ? packageById?.status : "Active",
              }}
              validationSchema={validationSchema}
              onSubmit={(va) => {
                console.log(va);
                if (id) {
                  console.log("VAVA", va);
                  EditPackage({
                    id: id,
                    editData: {
                      ...va,
                      subscription_amount: Math.ceil(
                        va.subscription_amount * 100
                      ),
                    },
                  })
                    .unwrap()
                    .then((res) => {
                      toast.success("Package updated successfully");
                      navigate("/subscription");
                    })
                    .catch((err) => {
                      toastError(
                        err?.data?.error ? err.data.error : "Server Error !"
                      );
                      // toast.error(
                      //   err ? err.data.message : "Something went wrong!!"
                      // );
                    });
                } else {
                  AddSubsPackage({
                    ...va,
                    subscription_amount: Math.ceil(
                      va.subscription_amount * 100
                    ),
                  })
                    .unwrap()
                    .then((res) => {
                      toast.success("Package added successfully");
                      navigate("/subscription");
                    })
                    .catch((err) => {
                      console.log("ERRROr", err);
                      // toast.error(
                      //   err ? err.data.error : "Something went wrong!!"
                      // );
                      toastError(
                        err?.data?.error ? err.data.error : "Server Error !"
                      );
                    });
                }
              }}
            >
              {({
                errors,
                touched,
                handleChange,
                setFieldValue,
                getFieldMeta,
                values,
                handleBlur,
              }) => (
                <Form>
                  <Grid
                    container
                    rowSpacing={{ xs: 3, md: 6 }}
                    columnSpacing={{ xs: 2, sm: 8, md: 12 }}
                  >
                    {initialValue?.map((item, i) => {
                      if (item == "status") {
                        return (
                          <>
                            <Grid item key={i}>
                              <FormLabel
                                display="block"
                                id="demo-controlled-radio-buttons-group"
                              >
                                Status
                              </FormLabel>
                              <RadioGroup
                                row
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="status"
                                defaultValue={values?.status}
                                onChange={handleChange}
                              >
                                <FormControlLabel
                                  value="Active"
                                  control={<Radio />}
                                  label="Active"
                                />
                                <FormControlLabel
                                  value="Inactive"
                                  control={<Radio />}
                                  label="InActive"
                                />
                              </RadioGroup>
                            </Grid>
                          </>
                        );
                      }
                      return (
                        <Grid item xs={12} sm={6} md={6} key={i}>
                          <TextField
                            name={item}
                            key={i}
                            onChange={handleChange}
                            required
                            disabled={ item == "validity"?true:false}
                            getFieldMeta
                            fullWidth
                            error={touched[item] && Boolean(errors[item])}
                            helperText={touched[item] && errors[item]}
                            onBlur={handleBlur}
                            defaultValue={values[item]}
                            type={
                              item == "subscription_amount" ||
                              item == "validity" ||
                              item == "allowed_sms"
                                ? "number"
                                : null
                            }
                            id="outlined-basic"
                            label={item.toUpperCase().replace(/_/g, " ")}
                            variant="outlined"
                            onkeydown={(e) => {
                              if (
                                !(
                                  (e.keyCode > 95 && e.keyCode < 106) ||
                                  (e.keyCode > 47 && e.keyCode < 58) ||
                                  e.keyCode == 8
                                )
                              ) {
                                e.preventDefault();
                              }
                            }}
                            onKeyPress={(e) => {
                              if (
                                e.target.value.toString().split(".")[1].length >
                                1
                              ) {
                                e.preventDefault();
                              }
                            }}
                            InputProps={{
                              inputProps: { step: "any", min: 0 },
                              startAdornment:
                                item === "subscription_amount" ? (
                                  <span>$</span>
                                ) : null,
                              endAdornment:
                                item === "validity" ? <span>days</span> : null,
                            }}
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                  <LoadingButton
                    style={{ marginTop: "10px" }}
                    loading={editPackageLoading || isLoading}
                    sx={{
                      width: "150px",
                      color: "#ffff",
                      height: "50px",
                    }}
                    loadingPosition="center"
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    <span>SUBMIT</span>
                  </LoadingButton>
                </Form>
              )}
            </Formik>
          ) : (
            <Loader />
          )}
        </Box>
      </Container>
    </>
  );
}
