import { Height } from "@mui/icons-material";
import {
  Box,
  Card,
  Grid,
  IconButton,
  Input,
  Modal,
  TextField,
  Typography,
  withStyles,
} from "@mui/material";
import React, { useRef, useState } from "react";
import { LinearProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";

//ICONS
import { GridCloseIcon } from "@mui/x-data-grid";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { useEffect } from "react";
import { toast } from "react-toastify";
import CloudDoneIcon from "@mui/icons-material/CloudDone";
import { useUploadMenuMutation } from "../../features/api/menuApi";
import { useParams } from "react-router-dom";
import toastError from "../../components/toasts/toast";

//STYLE
const progressStyle = makeStyles({
  root: {
    height: 15,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: "#EEEEEE",
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#1a90ff",
  },
});
export const FileUploader = ({ open, setOpen }) => {
  //STATES
  const [progress, setProgress] = useState(0);
  const [file, setFile] = useState(null);

  console.log(file);
  //DECLARATIONS
  const classProgress = progressStyle();
  const fileRef = useRef(null);
  const { vendor_id } = useParams();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    background: "white",
    // "radial-gradient(ellipse farthest-corner at right bottom, #FEDB37 0%, #FDB931 8%, #9f7928 30%, #8A6E2F 40%, transparent 80%), radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #FFFFAC 8%, #D1B464 25%, #5d4a1f 62.5%, #5d4a1f 100%)",

    boxShadow: 24,
    p: 4,
  };
  //APIS
  const [UploadMenu, { isLoading: uploadLoading }] = useUploadMenuMutation();
  // useEffect(() => {
  //   if (file) {
  //     const formData = new FormData();
  //     formData.append(`menuFile`, file);
  //     console.log("FD",formData);
  //     console.log("dsfdsfFD",file);
  //     UploadMenu({ menuFile: file, vendor_id: vendor_id })
  //       .unwrap()
  //       .then((res) => toast.success("Menu Upload SuccessFully"))
  //       .catch((err) =>
  //         toastError(
  //           err?.data?.error ? err.data.error : "Something went wrong !"
  //         )
  //       );
  //   }
  // }, [file]);
  useEffect(() => {});
  return (
    <Modal
      open={open}
      onClose={() => {
        setOpen(false);
        setProgress(0);
        setFile(null);
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box>
        <Card sx={style}>
          <Box>
            <Box sx={{ float: "right" }}>
              <IconButton
                onClick={() => {
                  setOpen(false);
                  setProgress(0);
                  setFile(null);
                }}
              >
                <GridCloseIcon />
              </IconButton>
            </Box>
          </Box>
          <Box
            display={"flex"}
            width={"100%"}
            flexDirection={"column"}
            justifyContent={"center"}
          >
            <Typography variant="h2" component={"span"} margin={"auto"}>
              File Upload
            </Typography>
            <Typography variant="h5" component={"span"} margin={"auto"} my={2}>
              ( PDF ONLY)
            </Typography>
            <IconButton
              sx={{
                dispaly: "flex",
                flexDirection: "column",
                color: progress == 100 ? "green" : "DodgerBlue",
              }}
              onClick={() => fileRef.current.click()}
            >
              {progress == 100 ? (
                <>
                  <CloudDoneIcon sx={{ fontSize: "250px" }} />
                  <Typography variant="h4" component={"h4"}>
                    File upload success
                  </Typography>
                </>
              ) : (
                <>
                  <CloudUploadIcon sx={{ fontSize: "250px" }} />
                  <Typography variant="h4" component={"h4"}>
                    Browse File To Upload
                  </Typography>
                </>
              )}
            </IconButton>
            <TextField
              inputRef={fileRef}
              type={"file"}
              inputProps={{
                accept: "application/pdf",
                multiple: true,
                draggable: true,
              }}
              onChange={async (e) => {
                let files = e.target.files;
                setProgress(0);
                setFile(files);
                const formData = new FormData();

                for (let i = 0; i < files?.length; i++) {
                  formData.append(`menufile[${i}]`, files[i]);
                }
                formData.append(`vendor_id`, vendor_id);
                console.log("dsfdsfFD", files);
                if (files[0]) {
                UploadMenu({ formData: formData, setProgress: setProgress })
                  .unwrap()
                  .then((res) => {
                    // toast.success("Menu uploaded successfully completed");
                    setOpen(false);
                    setFile(null);
                    setProgress(0);
                  })
                  .catch((err) =>
                    toastError(
                      err?.data?.error
                        ? err.data.error
                        : "Something went wrong !"
                    )
                  );
                }
              }}
              // InputProps={{
              //  component:<input type="file" multiple/>,

              // }}
              sx={{ display: "none" }}
            />
          </Box>
          {(uploadLoading || progress == 100) && (
            // <Modal  open={true}>
            <Card>
              <Grid
                container
                columnSpacing={2}
                justifyContent={"center"}
                alignItems={"center"}
                width="100%"
                mr={1}
                mt={5}
              >
                <Grid display={"grid"} item sm={12}>
                  <Typography
                    display={"flex"}
                    variant="h5"
                    margin={"auto"}
                    component={"div"}
                  >
                    {file?.name}
                  </Typography>
                </Grid>

                <Grid item>
                  <PictureAsPdfIcon sx={{ fontSize: 56 }} />
                </Grid>
                <Grid item sm={7}>
                  <LinearProgress
                    className={classProgress}
                    variant="determinate"
                    value={progress}
                  />
                </Grid>
                <Grid item sm={2}>
                  {progress}%
                </Grid>
              </Grid>
            </Card>
            // </Modal>
          )}
        </Card>
      </Box>
    </Modal>
  );
};
