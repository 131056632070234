import { mainApi } from "./mainApi";

export const SubscriptionApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    //LIST PACKAGE
    PackageList: builder.query({
      query: () => "/subscriptionmodel",
      providesTags: ["Subscription"],
    }),
    //VIEW PACKAGE
    PackageById: builder.query({
      query: (id) => `/subscriptionmodel/${id}`,
      providesTags: ["Subscription"],
    }),
    //DELETE PACKAGE
    PackageDelete: builder.mutation({
      query: (id) => ({
        url: `/subscriptionmodel/${id}`,
        method: "PUT",
      }),
      invalidatesTags: ["Subscription"],
    }),
    //ADD PACKAGE
    AddSubsPackage: builder.mutation({
      query: (packageData) => ({
        url: `/subscriptionmodel`,
        method: "POST",
        body: packageData,
      }),
      invalidatesTags:['Subscription']
    }),
    //EDIT PACKAGE
    EditPackage: builder.mutation({
      query: ({id, editData}) => ({
        url: `/subscriptionmodel/${id}`,
        method: "PATCH",
        body: editData,
      }),
      invalidatesTags: ["Subscription"],
    }),
  }),
});

export const {
  useAddSubsPackageMutation,
  usePackageListQuery,
  usePackageByIdQuery,
  usePackageDeleteMutation,
  useEditPackageMutation,
} = SubscriptionApi;
