import { createSlice } from "@reduxjs/toolkit"

const otherSlice = createSlice({
    name: "others",
    initialState: {
        details: null,
        customerList: [],
        breadCrumb: [],
    },
    reducers: {
        setDetails: (state, actions) => {
            state.details = actions.payload
        },
        clearDetails: (state) => {
            state.details = []
        },
        setCustomerLists: (state, action) => {
            state.customerList = action.payload
        },
        setBreadCrumb: (state, actions) => {
            state.breadCrumb = actions.payload
        },
    },
})

export const {
    setDetails,
    clearDetails,
    setCustomerLists,
    setBreadCrumb,
} = otherSlice.actions

export default otherSlice.reducer
