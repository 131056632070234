import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setBreadCrumb } from "../../features/slice/otherSlice";
import {
  Box,
  Button,
  IconButton,
  Stack,
  Tooltip,
  useTheme,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
//ICONS
import VisibilityIcon from "@mui/icons-material/Visibility";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import ModeOutlinedIcon from "@mui/icons-material/ModeOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import GetAppIcon from "@mui/icons-material/GetApp";

import dayjs from "dayjs";
import { tokens } from "../../global/theme";
import { FileUploader } from "./FileUploader";
import {
  useDeleteMenuMutation,
  useDownloadMenuMutation,
  useDownloadQRCodeMutation,
  useGetMenuListByVendorQuery,
} from "../../features/api/menuApi";
import { useParams } from "react-router-dom";
import fileDownload from "js-file-download";
import { toast } from "react-toastify";
import DeleteDialogue from "../../components/deletepopup/delete";
import toastError from "../../components/toasts/toast";
import Loader from "../../components/loader/Loader";
export default function MenuList() {
  //STATES
  const [open, setOpen] = useState(false);
  const [menu, setMenu] = useState([]);
  const [dId, setdId] = useState(null);
  //DECLARATIONS
  const dispatch = useDispatch();
  const { vendor_id } = useParams();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  let location = window.location;
  //APIs
  const { data, isLoading } = useGetMenuListByVendorQuery(vendor_id);
  const [DownloadQRCode, { isLoading: downloadLoading }] =
    useDownloadQRCodeMutation();
  const [DownloadMenu, { isLoading: menudownloadLoading }] =
    useDownloadMenuMutation();
  const [DeleteMenu, { isLoading: deleteLoading }] = useDeleteMenuMutation();
  //HANDLE QRCODE DOWNLAOD
  //Download Orders
  const handleDownload = async (type, item) => {
    if (type == "QRCODE") {
      const param = {
        menu_id: item,
      };
      const de = toast.loading("Downloading...");
      DownloadQRCode(param)
        .unwrap()
        .then((res) => {
          console.log(res);
          toast.update(de, {
            render: "Downloaded",
            type: "success",
            isLoading: downloadLoading,
            autoClose: 2000,
          });
          fileDownload(res, `${type}.pdf`);
        })
        .catch((err) => {
          toast.update(de, {
            render: err?.data?.error ?? "Something went Wrong!!",
            type: "error",
            isLoading: downloadLoading,
            autoClose: 2000,
          });
        });
    } else if (type === "MENU") {
      let path = item.path.split("/");
      const param = {
        menuLink: path[3] + "/" + path[4],
      };
      const de = toast.loading("Downloading...");
      DownloadMenu(param)
        .unwrap()
        .then((res) => {
          console.log(res);
          toast.update(de, {
            render: "Downloaded",
            type: "success",
            isLoading: menudownloadLoading,
            autoClose: 2000,
          });
          fileDownload(res, `${item.filename}`);
        })
        .catch((err) => {
          toast.update(de, {
            render: err?.data?.error ?? "Something went Wrong!!",
            type: "error",
            isLoading: downloadLoading,
            autoClose: 2000,
          });
        });
    }
  };
  //DELETE MENU
  const handleDelete = (id) => {
    DeleteMenu({ menuLink: id })
      .unwrap()
      .then(() => {
        toast.success("Menu Deleted Successfully");
        setdId(null);
      })
      .catch((err) =>
        toastError(
          err?.data?.error ? err?.data?.error : "Internal Server Error"
        )
      );
  };
  const columns = [
    {
      field: "index",
      headerName: "S/NO",
      align: "center",
      headerAlign: "center",
      flex: 1,
      minWidth: 100,
      // renderCell: (value, index) => value.api.getRowIndex(value.row._id) + 1,
    },
    // {
    //   field: "company_name",
    //   headerName: "COMPANY NAME",
    //   headerAlign: "center",
    //   align: "center",
    //   flex: 1,
    //   minWidth: 200,
    // },
    // {
    //   field: "email",
    //   headerName: "VENDOR EMAIL",
    //   headerAlign: "center",
    //   align: "center",
    //   minWidth: 250,
    // },
    {
      field: "filename",
      headerName: "MENU FILE",
      // headerAlign: "center",
      // align: "center",
      flex: 1,
      minWidth: 200,
      // flex: 1,
    },

    {
      field: "createdAt",
      headerName: "DATE & TIME",
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 150,
      renderCell: (value) => dayjs(value?.row?.date_time).format("MM/DD/YYYY"),
    },
    {
      field: "menuLink",
      headerName: "WEB LINK",
      // align: "center",
      // headerAlign: "center",
      flex: 1,
      minWidth: 150,
      renderCell: (value) => (
        <a key={value.row.menuLink} target="blank" href={value.row.menuLink}>
          {value.row.menuLink.split("/")[4]}
        </a>
      ),
      valueGetter: (value) => value.row.menuLink.split("/")[4],
    },
    {
      field: "qr_code",
      headerName: "QR CODE",
      align: "center",
      headerAlign: "center",
      flex: 1,
      minWidth: 100,
      sortable: false,
      renderCell: (value) => (
        <Button disabled={downloadLoading} onClick={() => handleDownload("QRCODE", value?.row?._id)}>
          Download
        </Button>
      ),
    },
    {
      field: "ACTIONS",
      headerAlign: "center",
      align: "center",
      disableColumnSelector: true,
      disableColumnMenu: true,
      disableColumnFilter: true,
      sortable: false,
      flex: 1,
      minWidth: 100,
      renderCell: (value) => {
        return (
          <div key={value.row._id}>
            <Tooltip title="View">
              <IconButton
                sx={{
                  color: colors.blueAccent[500],
                }}
                disabled={menudownloadLoading}
                onClick={() =>
                  handleDownload("MENU", {
                    path: value?.row?.menuLink,
                    filename: value?.row?.filename,
                  })
                }
              >
                <GetAppIcon />
              </IconButton>
            </Tooltip>

            <Tooltip title="Delete">
              <IconButton
                color="error"
                onClick={() => {
                  let path = (value?.row?.menuLink).split("/");

                  setdId(path[3] + "/" + path[4]);
                }}
              >
                <DeleteOutlineOutlinedIcon />
              </IconButton>
            </Tooltip>
          </div>
        );
      },
    },
  ];
  //USEEFFECTS
  useEffect(() => {
    dispatch(
      setBreadCrumb([
        {
          name: "VENDOR",
          path: "/vendors",
        },
        {
          name: "MENU",
          path: location.pathname,
        },
      ])
    );
  }, [dispatch]);
  useEffect(() => {
    if (data != undefined) {
      setMenu(data.data);
    }
  }, [data]);
  return (
    <>
      <Box m={1}>
        <Button
          variant="contained"
          sx={{
            color: "#ffff",
          }}
          color="primary"
          onClick={() => {
            setOpen(true);
          }}
        >
          Upload File
        </Button>
      </Box>
      <Box
        sx={{
          height: "100%",
          maxHeight: "90%",
        }}
      >
        <DataGrid
          disableSelectionOnClick
          rows={menu}
          getRowId={(row) => row._id}
          columns={columns}
          sx={{ cursor: "default" }}
          onRowDoubleClick={
            (v) => <></>
            //   navigate(`/vendors/view/${v?.row?._id}`)
          }
          components={{
            Toolbar: GridToolbar,
            NoRowsOverlay: () => {
              return (
                <Stack
                  height="100%"
                  alignItems="center"
                  justifyContent="center"
                >
                  {isLoading ? (
                    <Loader />
                  ) : !isLoading && data == undefined ? (
                    " Something went wrong"
                  ) : !data?.data[0] ? (
                    " No rows"
                  ) : null}
                </Stack>
              );
            },
          }}
          // pageSize={10} rowsPerPageOptions={[10,20,30]}
          // checkboxSelection
        />
      </Box>
      <FileUploader open={open} setOpen={setOpen} />
      {dId && (
        <DeleteDialogue
          dId={dId}
          setdId={setdId}
          handleDelete={handleDelete}
          isLoading={deleteLoading}
        />
      )}
    </>
  );
}
