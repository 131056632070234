import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  CircularProgress,
  colors,
  Divider,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { maxHeight } from "@mui/system";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import { InputField } from "../../../components/input/InputField";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useAddStripeConfigMutation } from "../../../features/api/stripe";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { setGetUser } from "../../../features/slice/authSlice";
import { useGetUserQuery } from "../../../features/api/authApi";
import toastError from "../../../components/toasts/toast";
import { setBreadCrumb } from "../../../features/slice/otherSlice";

export default function StripeConfig() {
  //STATES
  const [initialValues] = useState([
    "stripe_secret_key",
    "stripe_publishable_key",
    "stripe_currency",
    "stripe_connect_api_key",
  ]);
  const { GetUser, user } = useSelector((state) => state.auth);
  //DECLARATIONS
  const dispatch = useDispatch();
  //API
  const [AddStripeConfig, { isLoading: stripeLoading }] =
    useAddStripeConfigMutation();
  const {
    data: authData,
    isLoading: authLoading,
    refetch,
  } = useGetUserQuery(user?.admin._doc._id);

  // VALIDATION
  const validationSchema = yup.object().shape({
    stripe_secret_key: yup
      .string("Enter your Username")
      .required("Enter Stripe Secret Key"),
    stripe_publishable_key: yup
      .string("Enter Stripe Publishable Key")

      .required("Enter Stripe Publishable Key"),
    stripe_connect_api_key: yup
      .string("Enter Stripe Connect Api Key")
      .required("Enter Stripe Connect Api Key"),
  });
  //USEEFFECTS
  useEffect(() => {
    if (authData !== undefined) {
      dispatch(setGetUser(authData.data.admin));
    }
  }, [authData]);
  useEffect(() => {
    dispatch(
      setBreadCrumb([
        {
          name: "STRIPE",
          path: "/stripe_account",
        },
      ])
    );
  }, [dispatch, window.location]);
  return (
    <>
      <Grid
        item
        sx={{ background: "white", height: "80%" }}
        justifyContent="center"
        alignItems="center"
        md={8}
      >
        <Formik
          initialValues={{
            admin_id: GetUser?._id,
            stripe_secret_key: GetUser?.stripeConfig?.stripe_secret_key,
            stripe_publishable_key:
              GetUser?.stripeConfig?.stripe_publishable_key,
            stripe_currency: GetUser?.stripeConfig?.stripe_currency? GetUser?.stripeConfig?.stripe_currency:'USD',
            stripe_connect_api_key:
              GetUser?.stripeConfig?.stripe_connect_api_key,
          }}
          validationSchema={validationSchema}
          onSubmit={(v) => {
            AddStripeConfig(v)
              .unwrap()
              .then((res) => {
                refetch(user?.admin._doc._id);
                toast.success("Stripe configuration saved successfully");
              })
              .catch((err) =>
                toastError(err.data.error ? err.data.error : "Server Error !")
              );
          }}
          maxWidth="md"
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "50px",
          }}
        >
          {({
            errors,
            touched,
            handleChange,
            setFieldValue,
            getFieldMeta,
            values,
          }) => (
            <Form
              style={{
                display: "flex",
                gap: "20px",
                flexDirection: "column",
              }}
            >
              <Box padding={6}>
                <Typography variant="h3" component="h3" display={"inline"}>
                  Stripe Configuration
                </Typography>

                <Grid
                  container
                  rowSpacing={5}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  marginTop={3}
                >
                  {initialValues.map((item, index) => {
                    return (
                      <>
                        <InputField
                          key={index}
                          name={item}
                          disabled={item == "stripe_currency" ? true : false}
                          label={item}
                          getFieldMeta={getFieldMeta}
                          errors={errors}
                          setFieldValue={setFieldValue}
                          touched={touched}
                          values={values}
                          onChange={handleChange}
                          fullWidth
                          mdcol={6}
                        />
                      </>
                    );
                  })}{" "}
                  <Grid container justifyContent="center" alignItems="flex-end">
                    <Grid item sx={{ mt: 5 }}>
                      <LoadingButton
                        sx={{
                          width: "150px",
                          color: "#ffff",
                        }}
                        loading={stripeLoading}
                        loadingPosition="center"
                        type="submit"
                        variant="contained"
                        color="primary"
                      >
                        <span>Save</span>
                      </LoadingButton>{" "}
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Form>
          )}
        </Formik>
      </Grid>
    </>
  );
}
