import { Container, Grid, Button, Typography, TextField, FormControl, InputLabel, MenuItem, OutlinedInput, FormHelperText, Input, Autocomplete } from "@mui/material"
import { Form, Formik } from "formik"
import React, { useRef, useState } from "react"
import { useForm, Controller } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import * as yup from "yup"
import { useAddCardDetailsMutation } from "../../../features/api/vendorApi"

import { yupResolver } from "@hookform/resolvers/yup"



const validationSchema = yup.object().shape({
    firstname: yup
        .string("Enter your firstname")
        .required("Firstname is required"),
    lastname: yup
        .string("Enter your lastname")
        .required("Lastname is required"),
    // company_name: yup
    //     .string("Enter your company name")
    //     .required("Company name is required"),
    webusername: yup
        .string("Enter your username")
        .required("Username is required"),
    // email: yup
    //     .string("Enter your email")
    //     .email("Enter a valid email")
    //     .required("Email is required"),
    city: yup.string("Enter your city").required("City is required"),
    // area: yup.string("Enter your area").required("Area is required"),
    // subscription_type: yup
    //     .string("Select subscription type")
    //     .required("Subscription type is required"),
    // phone: yup
    //     .number("Enter your phone number")
    //     .required("Phone number is required"),
    // country: yup.string("Select your Country").required("Country is required"),
    // state: yup.string("Select your State").required("State is required"),
    // twilio_number: yup.number("Enter your twilio number"),
    // address: yup.string("Enter your address").required("Address is required"),
    // image: yup
    //   .mixed(),
    // .test("fileSize", "The file is too large", (value) => {
    //   return value && value[0].sienter <= 2000000;
    // })
    // .test("type", "Only the following formats are accepted: .jpeg, .jpg, .bmp, .pdf and .doc", (value) => {
    //   return value && (
    //     value[0].type === "image/*"
    //   );
    // }),
})

const options = (params) => {
    if (params === "state") {
        return ["California", "Alaska"]
    } else if (params === "subscription_type") {
        return ["Monthly", "Yearly"]
    } else {
        // return [<InputBase fullWidth />]
        return ["INDIA", "US"]
    }
}

const Text = ({ errors, name, label, register, setValue, watch }) => {
    return (
        <TextField
            sx={{ mb: 2 }}
            label={label}
            fullWidth
            // required
            onChange={(e) => {
                console.log(e)
            }}
            // {...register(name)}
            type='text'
            error={Boolean(errors[name])}
            helperText={
                errors[name] ? errors[name]?.message : ''
            }
        />
    )
}
const Select = ({ errors, name, label, register, setValue, options, watch }) => {
    const handleChange = (e, values) => {
        setValue(name, values)
    }
    return (
        <Autocomplete
            disablePortal
            label={label}
            id={name}
            onChange={handleChange}
            sx={{ width: 300 }}
            options={options}
            renderInput={(props) => <TextField {...props} error={Boolean(errors[name])} helperText={errors[name] ? errors[name]?.message : ''
            } label={label} />}
        />
    )
}



const AddEditCard = () => {
    const [state, setState] = useState({
        firstname: "",
        lastname: ""
    })
    const { register, setValue, handleSubmit, watch, formState, setFocus, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema)
    });
    console.log()
    return (
        <form onChange={() => {
            const firstname = watch('firstname')
            const lastname = watch('lastname')
            // setState({...others,  })
        }} onSubmit={handleSubmit((values) => { console.log(values) })}>
            <Text name="firstname" setValue={setValue} watch={watch} label={"Firstname"} errors={errors} register={register} />
            <Text name="lastname" label={"lastname"} errors={errors} register={register} />
            <Text value={`${state.firstname} ${state.lastname}`} name="webusername" label={"Username"} className="d-none" errors={errors} register={register} />
            <Select name="city" label={"City"} setValue={setValue} watch={watch} errors={errors} options={options('city')} />
            <Button type="submit">Submit</Button>
        </form>
    )
}



export default AddEditCard