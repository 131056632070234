import { mainApi } from "./mainApi";

//Vendor List
export const vendorApi = mainApi.injectEndpoints({
    endpoints: (builder) => ({
        //Vendor List
        vendorList: builder.query({
            query: () => "vendor/",
            providesTags: ["Vendor"],
        }),
        //Vendor include Feedback
        vendorFeedbackById : builder.query({
            query: (id) => `vendor/${id}?include6=feedback`,
            providesTags: ["Feedback"],
        }),
        //Single Vendor with all Details
        vendorWithIncludes: builder.query({
            query: (id) =>
                `vendor/${id}?include2=customerpayment&include4=billdetail&include5=invoicedetail`,
            providesTags: ["Vendor"],
        }),
        //Send Invoice
        vendorSendInvoice: builder.mutation({
            query: (id) => ({
                url: `invoice?id=${id}`,
                method: "POST",
            }),
        }),
        //Delete Vendor
        vendorDelete: builder.mutation({
            query: (id) => ({
                url: `vendor/${id}`,
                method: "PUT",
            }),
            invalidatesTags: ["Vendor"],
        }),
        //Add Vendor
        addVendor: builder.mutation({
            query: (VendorData) => ({
                url: `vendor/register`,
                method: "POST",
                body: VendorData,
            }),
            invalidatesTags: ["Vendor"],
        }),
        //Edit Vendor
        editVendor: builder.mutation({
            query: ({ id, editData }) => ({
                url: `vendor/edit/${id}`,
                method: "PUT",
                body: editData,
            }),
            invalidatesTags: ["Vendor"],
        }),
        //Add Subcription
        addSubscription: builder.mutation({
            query: (SubscriptionData) => ({
                url: `subscription`,
                method: "POST",
                body: SubscriptionData,
            }),
            invalidatesTags: ["Vendor"],
        }),
        //Add Vendor Card
        addCardDetails: builder.mutation({
            query: (cardDetails) => ({
                url: "addcard",
                method: "POST",
                body: cardDetails,
            }),
            providedTags: ["Vendor"],
        }),
    }),
});

export const {
    useVendorListQuery,
    useVendorFeedbackByIdQuery,
    useAddVendorMutation,
    useVendorWithIncludesQuery,
    useVendorDeleteMutation,
    useAddSubscriptionMutation,
    useEditVendorMutation,
    useAddCardDetailsMutation,
    useVendorSendInvoiceMutation,
} = vendorApi;
