import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Container } from "@mui/system";
import { Autocomplete, Grid, IconButton, TextField } from "@mui/material";
import { InputField, SelectField } from "../../components/input/InputField";
import { Formik, Form } from "formik";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LoadingButton } from "@mui/lab";

import * as yup from "yup";
import {
  useVendorDeleteMutation,
  useVendorListQuery,
} from "../../features/api/vendorApi";
import { useState } from "react";
import {
  useAddCardMutation,
  useEditCardMutation,
} from "../../features/api/cardapi";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { PartyModeSharp } from "@mui/icons-material";
import dayjs, { Dayjs } from "dayjs";
import ExpiryDate from "./ExpiryDate";
import { usePaymentInputs } from "react-payment-inputs";
//CSS
import "./card.css";
//ICONS
import CloseIcon from "@mui/icons-material/Close";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import { useDispatch, useSelector } from "react-redux";
import { setCardDetail } from "../../features/slice/vendorSlice";
import InputMask from "react-input-mask";
import toastError from "../../components/toasts/toast";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: { sm: `calc(100% - ${drawerWidth}px)` },
  // height: {xs:"85%",md:"%"},
  width: { xs: "65%", md: "35%" },
  // eight:"1000px",
  bgcolor: "background.paper",
  border: "2px solid white",
  boxShadow: 24,

  // p: {lg:4},
  py: 2,
};
export default function AddCard({ open, setOpen, vendor }) {
  const navigate = useNavigate();
  const { data } = useVendorListQuery();
  const date = dayjs();
  //aAPI
  const [AddCard, { isLoading }] = useAddCardMutation();
  const [EditCard, { isLoading: editCardLoading }] = useEditCardMutation();

  const [addCard, setAddCard] = useState({});
  const { cardDetail, vendor: venD } = useSelector((state) => state.vendor);
  const dispatch = useDispatch();
  console.log("VEND", venD);
  const handleOpen = () => setOpen(true);
  const [vendors, setVendors] = useState([]);
  const handleClose = () => {
    dispatch(setCardDetail(null));
    setOpen(false);
  };
  console.log("VENDOR", vendor);
  const [initialValues] = React.useState([
    "vendor_name",
    "name",
    "number",
    "expiry_date",
    "cvc",
  ]);

  //VALIDATION ADD CARD
  let validationSchema;
  if (cardDetail == null) {
    validationSchema = yup.object().shape({
      name: yup
        .string("Enter your Username")
        .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ")
        .required("Firstname is required"),
      cvc: yup
        .number("Enter your CVC")
        .typeError("CVC must be a number")
        .min(100, "Should be 3 characters.")
        // .required("CVC is required")
        .max(999, "Should be 3 characters."),
      number: yup
        .number("Enter Card Number")
        .typeError("Card Number must be a number")
        .required("Phone Number is Required")
        .min(1000000000000000, "Should be 16 characters.")
        .max(9999999999999999, "Should be 16 characters."),
      exp_month: yup
        .number("Enter Month")
        .typeError("Enter Valid Month")
        .required("Month is Required")
        .min(1, "Enter Valid Month")
        .max(12, "Enter Valid Month"),
      exp_year: yup
        .number("Enter Year")
        .typeError("Enter Valid Year")
        .required("Year is Required")
        .min(23, "Enter valid Year")
        .max(99, "Enter Valid Year"),
    });
  }
  if (cardDetail !== null) {
    validationSchema = yup.object().shape({
      name: yup
        .string("Enter your Username")
        .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ")
        .required("Firstname is required"),
      exp_month: yup
        .number("Enter Month")
        .required("Month is Required")
        .min(1, "Enter Valid Montg")
        .max(12, "Enter Valid Month"),
      exp_year: yup
        .number("Enter Year")
        .required("Year is Required")
        .min(23, "Enter valid Year")
        .max(99, "Enter Valid Year"),
    });
  }
  React.useEffect(() => {
    if (data !== undefined) {
      setVendors(data?.data);
    }
  }, [data]);
  console.log("vendors", vendors);
  console.log("AddCArd", addCard);
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className={"addcard"}>
          <Box display={"flex"} sx={{ float: "right" }}>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Container>
            <Box paddingY={2}>
              <Typography variant="h6" component="h6">
                {cardDetail ? "Edit Card" : " Add Card"}
              </Typography>

              <Formik
                initialValues={{
                  id: vendor?._id ? vendor?._id : venD?._id,
                  stripe_id: vendor?.stripeid
                    ? vendor?.stripeid
                    : venD?.stripeid,
                  exp_month: cardDetail?.exp_month,
                  exp_year: cardDetail?.exp_year,
                  number: cardDetail?.last4
                    ? "**** **** **** " + cardDetail.last4
                    : "",
                  name: cardDetail?.name,
                  cvc: cardDetail?.cvc,
                }}
                validationSchema={validationSchema}
                onKeyUp={(values) => {
                  console.log(values);
                }}
                onSubmit={(values) => {
                  console.log("VALUES", values);
                  if (cardDetail === null) {
                    AddCard({
                      ...values,
                      id: values.id,
                      stripe_id: values.stripe_id,
                      name: values.name,
                      cvc: values.cvc,
                      number: values.number,
                    })
                      .unwrap()
                      .then((res) => {
                        toast.success("Card added successfully");
                        dispatch(setCardDetail(null));
                        setOpen(false);
                      })
                      .catch((err) => {
                        console.log("ERRROr", err);
                        toastError(
                          err?.data ? err.data.error : "Server Error !"
                        );
                      });
                  } else {
                    EditCard({
                      stripe_id: cardDetail.customer,
                      name: values.name,
                      exp_month: values.exp_month,
                      exp_year: values.exp_year,
                      id: values.id,
                      card_id: cardDetail.id,
                    })
                      .unwrap()
                      .then((res) => {
                        toast.success("Card Updated");
                        dispatch(setCardDetail(null));
                        setOpen(false);
                      })
                      .catch((err) => {
                        console.log("ERRROr", err);
                        toastError(
                          err.data ? err.data.error : "Server Error !"
                        );
                      });
                  }
                }}
                maxWidth="md"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "50px",
                }}
              >
                {({
                  errors,
                  touched,
                  handleChange,
                  setFieldValue,
                  getFieldMeta,
                  values,
                  handleBlur,
                }) => (
                  <Form
                  // style={{
                  //   display: "flex",
                  //   gap: "20px",
                  //   flexDirection: "column",
                  // }}
                  >
                    <Box>
                      <Grid
                        container
                        rowSpacing={5}
                        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                      >
                        {initialValues.map((item, index) => {
                          if (item == "vendor_name") {
                            return (
                              <>
                                {vendor ? null : (
                                  <Grid item xl={12} lg={12} sm={6} xs={12}>
                                    <Autocomplete
                                      required
                                      key={index}
                                      id={index}
                                      // defaultValue={{first_name:"hj",company_name:'jdj'}}
                                      name={item}
                                      getOptionLabel={(option) =>
                                        `${option.firstname}/${option.company_name}`
                                      }
                                      error={
                                        touched[item] && Boolean(errors[item])
                                      }
                                      onBlur={handleBlur}
                                      helperText={touched[item] && errors[item]}
                                      options={vendors}
                                      onChange={(e, v) => {
                                        console.log(e.target);
                                        Promise.all([
                                          setFieldValue("id", v._id),
                                          setFieldValue(
                                            "stripe_id",
                                            v.stripeid
                                          ),
                                        ]);
                                      }}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          value={"hello"}
                                          label={`Search for ${item.replace(
                                            "_",
                                            " "
                                          )}`}
                                        />
                                      )}
                                    />
                                  </Grid>
                                )}
                              </>
                            );
                          }
                          if (item === "expiry_date") {
                            return (
                              <>
                                <Grid item xl={4} lg={4} sm={6} xs={12}>
                                  <ExpiryDate
                                    handleChange={handleChange}
                                    errors={errors}
                                    touched={touched}
                                    values={values}
                                    onBlur={handleBlur}
                                  />
                                </Grid>
                              </>
                            );
                          }
                          if (item === "number" && cardDetail == null) {
                            return (
                              <>
                                <Grid item xl={12} lg={12} sm={12} xs={12}>
                                  <InputMask
                                    mask="9999  9999  9999  9999"
                                    onChange={(e) =>
                                      setFieldValue(
                                        "number",
                                        parseInt(
                                          e.target.value.replaceAll(" ", "")
                                        )
                                      )
                                    }
                                    type={"text"}
                                    disabled={false}
                                    onBlur={handleBlur}
                                    maskChar="*"
                                  >
                                    {() => (
                                      <TextField
                                        fullWidth
                                        key={index}
                                        name={item}
                                        label={item.toUpperCase()}
                                        error={
                                          touched[item] && Boolean(errors[item])
                                        }
                                        helperText={
                                          touched[item] && errors[item]
                                        }
                                        // required
                                        InputProps={{
                                          inputProps: {
                                            step: "any",

                                            style: { letterSpacing: 5 },
                                          },
                                          // startAdornment:
                                          //   item === "subscription_amount" ? (
                                          //     <span>$</span>
                                          //   ) : null,
                                          endAdornment:
                                            item === "number" ? (
                                              <CreditCardIcon />
                                            ) : null,
                                        }}
                                        // inputProps={{ style: { fontSize: 25 } }}
                                      />
                                    )}
                                  </InputMask>
                                </Grid>
                              </>
                            );
                          }
                          return (
                            <>
                              <InputField
                                key={index}
                                name={item}
                                label={
                                  item === "name" ? "CARD HOLDER NAME" : item
                                }
                                defaultValue={
                                  item == "cvc" && cardDetail !== null
                                    ? "***"
                                    : values[item]
                                }
                                disabled={
                                  (item == "cvc" || item == "number") &&
                                  cardDetail !== null
                                    ? true
                                    : false
                                }
                                inputFormat="MM-YYYY"
                                getFieldMeta={getFieldMeta}
                                errors={errors}
                                setFieldValue={setFieldValue}
                                touched={touched}
                                values={values}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                fullWidth
                                mdcol={item === "cvc" ? 4 : 12}
                                type={
                                  item == "number" || item == "cvc"
                                    ? "text"
                                    : item == "expiry_date"
                                    ? "month"
                                    : "text"
                                }
                                onKeyPress={(evt) => {
                                  if (
                                    item == "cvc" &&
                                    ((evt.which != 8 &&
                                      evt.which != 0 &&
                                      evt.which < 48) ||
                                      evt.which > 57)
                                  ) {
                                    evt.preventDefault();
                                  }
                                  if (
                                    item === "name" &&
                                    !(
                                      (evt.which != 8 &&
                                        evt.which != 0 &&
                                        evt.which < 48) ||
                                      evt.which > 57
                                    )
                                  ) {
                                    evt.preventDefault();
                                  }
                                }}
                                InputProps={{
                                  inputProps: {
                                    step: "any",
                                    style: {
                                      letterSpacing:
                                        item == "number" ? 16 : null,
                                    },
                                    maxLength: item == "cvc" ? 3 : null,
                                  },
                                  // startAdornment:
                                  //   item === "subscription_amount" ? (
                                  //     <span>$</span>
                                  //   ) : null,
                                  endAdornment:
                                    item === "number" ? (
                                      <CreditCardIcon />
                                    ) : null,
                                }}
                              />
                            </>
                          );
                        })}{" "}
                        <Grid
                          container
                          justifyContent="center"
                          alignItems="flex-end"
                          marginTop={3}
                        >
                          <Grid item>
                            <LoadingButton
                              sx={{
                                padding: "12px",
                                paddingX: "30px",
                                color: "#ffff",
                              }}
                              loading={isLoading || editCardLoading}
                              loadingPosition="center"
                              type="submit"
                              variant="contained"
                              color="primary"
                            >
                              <span>Save</span>
                            </LoadingButton>{" "}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Box>
                  </Form>
                )}
              </Formik>
            </Box>
          </Container>
        </Box>
      </Modal>
    </div>
  );
}
