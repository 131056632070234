import {
  Autocomplete,
  FormHelperText,
  Grid,
  MenuItem,
  TextField,
} from "@mui/material";

export const InputField = ({
  name,
  type,
  label,
  errors,
  touched,
  values,
  setFieldValue,
  getFieldMeta,
  InputProps,
  ...others
}) => {
  return (
    <Grid
      item
      xl={others.mdcol ? others.mdcol : 3}
      lg={others.mdcol ? others.mdcol : 3}
      sm={others.mdcol ? others.mdcol : 6}
      xs={12}
    >
      <TextField
        fullWidth
        name={name}
        id={name}
        defaultValue={values[name]}
        InputProps={InputProps}
        onKeyDown={(e) => {
          if (name === "number") {
            if (e.target.value.length === 4) {
              setFieldValue(name, e.target.value + "  ");
            }
            if (e.target.value.length === 10) {
              setFieldValue(name, e.target.value + "  ");
            }
          }
        }}
        // onBlur={
        //     name === "firstname" || name === "lastname"
        //         ? () => {
        //               const Username =
        //                   getFieldMeta("firstname")?.value +
        //                   `${
        //                       getFieldMeta("lastname")?.value !== ""
        //                           ? " " +
        //                             getFieldMeta("lastname")?.value
        //                           : ""
        //                   }`
        //               setFieldValue("webusername", Username)
        //           }
        //         : () => {}
        // }
        className={name === "" ? "d-none" : ""}
        value={values[name]}
        label={label
          .toString()
          .toUpperCase()
          .replaceAll("_", " ")
          .replace("WEB", "")}
        type={type ? type : "text"}
        error={touched[name] && Boolean(errors[name])}
        helperText={touched[name] && errors[name]}
        {...others}
      />
    </Grid>
  );
};

export const SelectField = ({
  name,
  label,
  errors,
  touched,
  values,
  options,
  onChange,
}) => {
  if (name == "vendor_name" || name == "country_code") {
    console.log("VALUESSS", values);
    return (
      <>
        <Grid item xl={12} lg={12} sm={6} xs={12}>
          <Autocomplete
            fullWidth
            id={name}
            name={name}
            // label={label.toString().toUpperCase().replace("_", " ")}
            getOptionLabel={(option) =>
              `${option.firstname}/${option.company_name}`
            }
            variant="outlined"
            error={touched[name] && Boolean(errors[name])}
            helperText={touched[name] && errors[name]}
            onChange={onChange}
            options={options ? options : [""]}
            // renderOption={(props, option) => {
            //   return (
            //     <li {...props} key={option.lastname}>
            //       {option.firstname}
            //     </li>
            //   );
            // }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={`Search for ${name.replace("_", " ")}`}
              />
            )}
          >
            {/* {options?.map((item, index) => {
              return (
                <MenuItem key={index} value={item}>
                  {item}
                </MenuItem>
              );
            })} */}
          </Autocomplete>
        </Grid>
      </>
    );
  } else {
    return (
      <Grid item xl={3} lg={3} sm={6} xs={12}>
        {/* {touched[name] &&  (
                <FormHelperText color="primary">{errors[name]}</FormHelperText>
            )}  */}
        <TextField
          fullWidth
          id={name}
          name={name}
          select
          label={label.toString().toUpperCase().replace("_", " ")}
          value={values[name]}
          variant="outlined"
          error={touched[name] && Boolean(errors[name])}
          helperText={touched[name] && errors[name]}
          onChange={onChange}
        >
          {options?.map((item, index) => {
            return (
              <MenuItem key={index} value={item}>
                {item}
              </MenuItem>
            );
          })}
        </TextField>
      </Grid>
    );
  }
};
