import { LoadingButton } from "@mui/lab";
import {
  Button,
  Grid,
  IconButton,
  InputBase,
  Stack,
  Tooltip,
  colors,
  useTheme,
} from "@mui/material";
import { Box, Container } from "@mui/system";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { Navigate, NavLink, useNavigate } from "react-router-dom";
import { setBreadCrumb, setDetails } from "../../features/slice/otherSlice";
import { useDispatch } from "react-redux";
import {
  usePackageDeleteMutation,
  usePackageListQuery,
} from "../../features/api/subscription";
//ICONS
import ChatIcon from "@mui/icons-material/Chat";
import VisibilityIcon from "@mui/icons-material/Visibility";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import ModeOutlinedIcon from "@mui/icons-material/ModeOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { tokens } from "../../global/theme";
import { toast } from "react-toastify";
import DeleteDialogue from "../../components/deletepopup/delete";
import ViewPackage from "./ViewPackage";
import ExpiryDate from "../paymentMethods/ExpiryDate";
import Loader from "../../components/loader/Loader";

export default function Subscribtion() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const [dId, setdId] = useState(null);
  const [view, setView] = useState(null);
  const colors = tokens(theme.palette.mode);
  const [subsList, setSubsList] = useState([]);
  const { data, isLoading } = usePackageListQuery();
  const [PackageDelete, { isLoading: deleteLoading }] =
    usePackageDeleteMutation();
  const handleDelete = (id) => {
    PackageDelete(id)
      .unwrap()
      .then((res) => {
        toast.success("Package Deleted SuccessFully");
        setdId(null);
      })
      .catch((err) =>
        toast.error( err?.data?.error ? err?.data.error : "Something went wrong!!")
      );
  };
  const columns = [
    // {
    //   field: "unique_id",
    //   headerName: "VENDOR ID",
    //   align: "center",
    //   headerAlign: "center",
    //   flex: 1,
    //   maxWidth: 200,
    //   renderCell: (value) => `${value.row.unique_id}`,
    // },
    {
      field: "subscription_type",
      headerName: "Package Name",
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "subscription_amount",
      headerName: "Subscription Price",
      headerAlign: "center",
      align: "center",
      minWidth: 150,
      flex: 1,
      renderCell: (v) => `$${(v?.row?.subscription_amount / 100).toFixed(2)}`,
    },

    {
      field: "validity",
      headerName: "Validity(DAYS)",
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 150,
      //   renderCell: (value) => dayjs(value?.row?.date_time).format("DD-MM-YYYY"),
    },
    {
      field: "status",
      headerName: "Status",
      align: "center",
      headerAlign: "center",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "Vendors",
      headerName: "Number Of Subscribers",
      align: "center",
      headerAlign: "center",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "Actions",
      headerAlign: "center",
      align: "center",
      sortable: false,
      disableColumnSelector: true,
      disableColumnMenu: true,
      disableColumnFilter: true,
      flex: 1,
      minWidth: 150,
      renderCell: (value) => {
        return (
          <>
            <Tooltip title="View">
              <IconButton
                sx={{
                  color: colors.blueAccent[500],
                }}
                onClick={() => setView(value?.row)}
              >
                <VisibilityIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Edit">
              <IconButton
                sx={{
                  color: "#ef9834",
                }}
                onClick={() => {
                  dispatch(setDetails(value.row));
                  navigate(`/subscription/edit/package/${value?.row?._id}`);
                }}
              >
                <ModeOutlinedIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete">
              <IconButton
                color="error"
                // onClick={() => handleDelete(value?.row?._id)}
                onClick={() => setdId(value?.row?._id)}
              >
                <DeleteOutlineOutlinedIcon />
              </IconButton>
            </Tooltip>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(
      setBreadCrumb([
        {
          name: "SUBSCRIPTIONS",
          path: "/subscription",
        },
      ])
    );
  }, [dispatch, window.location]);
  useEffect(() => {
    if (data != undefined) {
      setSubsList(data.data);
      console.log("SUBSLIST", data.data);
    }
  }, [data]);
  return (
    <>
      <Box
        sx={{
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "5px",
        }}
      >
        <Box
          display="flex"
          flexWrap={"wrap"}
          alignItems={"center"}
          justifyContent={"space-between"}
          paddingX={1}
          paddingY={1}
        >
          <Box>
            <Button
              variant="contained"
              sx={{
                color: "#ffff",
              }}
              color="primary"
              onClick={() => {
                navigate("/subscription/addpackage");
              }}
            >
              Add Package
            </Button>
          </Box>
          <Box
            sx={{
              display: "flex",
              borderRadius: "5px",
            }}
          ></Box>
        </Box>
        <Box
          sx={{
            height: "100%",
            maxHeight: "90%",
          }}
        >
          <DataGrid
            getRowId={(op) => op.subscription_type}
            disableSelectionOnClick
            rows={subsList ? subsList : []}
            components={{
              Toolbar: GridToolbar,
              NoRowsOverlay: () => {
                return (
                  <Stack
                    height="100%"
                    alignItems="center"
                    justifyContent="center"
                  >
                    {isLoading ? (
                      <Loader/>
                    ) : !isLoading && data == undefined ? (
                      " Something went wrong"
                    ) : !data?.data[0] ? (
                      " No rows"
                    ) : null}
                  </Stack>
                );
              },
            }}
            columns={columns}
          />{" "}
        </Box>
      </Box>
      {dId && (
        <DeleteDialogue
          dId={dId}
          setdId={setdId}
          handleDelete={handleDelete}
          isLoading={deleteLoading}
        />
      )}
      <ViewPackage view={view} setView={setView} />
    </>
  );
}
