import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Card, DialogContentText, IconButton } from "@mui/material";
import { GridCloseIcon } from "@mui/x-data-grid";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  background: "white",
  // "radial-gradient(ellipse farthest-corner at right bottom, #FEDB37 0%, #FDB931 8%, #9f7928 30%, #8A6E2F 40%, transparent 80%), radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #FFFFAC 8%, #D1B464 25%, #5d4a1f 62.5%, #5d4a1f 100%)",

  boxShadow: 24,
  p: 4,
};

export default function ViewPackage({ view, setView }) {
  console.log("VIEW", view);
  return (
    <Modal
      open={view !== null}
      onClose={() => setView(null)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box>
        {view && (
          <Card sx={style} className="gold">
            <Box>
              <Box  sx={{ float: "right" }}>
                <IconButton onClick={() => setView(null)}>
                  <GridCloseIcon />
                </IconButton>
              </Box>
            </Box>
            <DialogContentText display={"block"}>
              <span>
                <Typography id="modal-modal-title" variant="h2" component="h2">
                  {view.subscription_type.toUpperCase()}
                </Typography>
                <Typography
                  id="modal-modal-description"
                  sx={{ mt: 5 }}
                  variant="h4"
                >
                  ${(view.subscription_amount)/100 + "/" + view.validity + " days"}
                </Typography>

                <Typography
                  id="modal-modal-description"
                  sx={{ mt: 5 }}
                  variant="h5"
                  component="h5"
                >
                  {view.Vendors} Subscribers
                </Typography>

                <Typography
                  id="modal-modal-description"
                  sx={{ mt: 5 }}
                  variant="h6"
                >
                  Allowed Messages : {view.allowed_sms}
                </Typography>
              </span>
            </DialogContentText>
          </Card>
        )}
      </Box>
    </Modal>
  );
}
