import {
  Box,
  FormControl,
  IconButton,
  NativeSelect,
  Stack,
  Tooltip,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import dayjs from "dayjs";
import React, { useEffect } from "react";

//ICONs
import ChatIcon from "@mui/icons-material/Chat";
import VisibilityIcon from "@mui/icons-material/Visibility";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import ModeOutlinedIcon from "@mui/icons-material/ModeOutlined";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import AddIcon from "@mui/icons-material/Add";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import { useDispatch } from "react-redux";
import { setBreadCrumb } from "../../features/slice/otherSlice";
import {
  useDeletePartnerMutation,
  useDeletePartnerQuery,
  useGetPartnerQuery,
  useUpdatePartnerMutation,
} from "../../features/api/partnerLeads";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import DeleteDialogue from "../../components/deletepopup/delete";
import { toast } from "react-toastify";
import { ViewPartner } from "./ViewPartner";
import Loader from "../../components/loader/Loader";

export const PartnerLeads = () => {
  //STATES
  const [PartnerLeads, setPartnerLeads] = useState([]);
  const [dId, setdId] = useState(null);
  const [view, setView] = useState(null);
  //DECARATIONS
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //APIS
  const { data, isLoading } = useGetPartnerQuery();
  const [DeletePartner, { isLoading: deleteLoading }] =
    useDeletePartnerMutation();
  const [UpdatePartner, { isLoading: upadateLoading }] =
    useUpdatePartnerMutation();

  //HANDLE DELETE
  const handleDelete = (id) => {
    DeletePartner({ partner_id: id })
      .unwrap()
      .then((res) => {
        toast.success("Lead deleted successfully");
        setdId(null);
      })
      .catch((err) =>
        toast.error(err?.data?.error ?? "Something went wrong!!")
      );
  };
  const columns = [
    {
      field: "index",
      headerName: "S.NO",
      align: "center",
      headerAlign: "center",
      flex: 1,
      minWidth: 50,
    },
    {
      field: "name",
      headerName: "NAME",
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 100,
      valueGetter: (value) => value.row.firstname +"  "+ value.row.lastname,
    },
    {
      field: "nature_of_business",
      headerName: "NATURE OF BUSINESS",
      headerAlign: "center",
      align: "center",
      minWidth: 200,
    },
    {
      field: "message",
      headerName: "MESSAGE",
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 150,
      // flex: 1,
    },
    {
      field: "mobile_number",
      headerName: "PHONE NUMBER",
      align: "center",
      headerAlign: "center",
      flex: 1,
      minWidth: 100,
      valueGetter: (value) => value?.row?.mobile_number,
    },
    {
      field: "createdAt",
      headerName: "DATE & TIME",
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 100,
      valueGetter: (value) => dayjs(value?.row?.date_time).format("MM/DD/YYYY"),
    },
    {
      field: "status",
      headerName: "STATUS",
      align: "center",
      headerAlign: "center",
      flex: 1,
      minWidth: 170,
      renderCell: (value) => {
        return (
          <>
            <FormControl fullWidth>
              <NativeSelect
                // defaultValue={value?.row?.status}
                value={value?.row?.status}
                onChange={(e) =>
                  UpdatePartner({
                    partner_id: value?.row?._id,
                    status: e.target.value,
                  })
                    .unwrap()
                    .then((res) => toast.success(`Status Changed`))
                    .catch((e) =>
                      toast.error("Status not changed at this moment")
                    )
                }
              >
                <option value={"Completed"}>Completed</option>
                <option value={"Pending"}>Pending</option>
              </NativeSelect>
            </FormControl>
          </>
        );
      },
    },
    {
      field: "ACTIONS",
      headerAlign: "center",
      align: "center",
      disableColumnSelector: true,
      disableColumnMenu: true,
      disableColumnFilter: true,
      sortable: false,
      flex: 1,
      minWidth: 200,
      renderCell: (value) => {
        return (
          <>
            <Tooltip title="Add Vendor">
              <IconButton
                sx={{
                  color: "#ef9834",
                }}
                onClick={() => {
                  // dispatch(setDetails(value.row));
                  navigate(`/vendors/add/${value?.row?._id}`);
                }}
              >
                <DoubleArrowIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="View">
              <IconButton
                sx={
                  {
                    //   color: colors.blueAccent[500],
                  }
                }
                onClick={() => setView(value?.row)}
              >
                <VisibilityIcon />
              </IconButton>
            </Tooltip>

            <Tooltip title="Delete">
              <IconButton color="error" onClick={() => setdId(value?.row?._id)}>
                <DeleteOutlineOutlinedIcon />
              </IconButton>
            </Tooltip>
          </>
        );
      },
    },
  ];
  //BreadCrumb
  useEffect(() => {
    dispatch(
      setBreadCrumb([
        {
          name: "PARTNER LEADS",
          path: "/partner_leads",
        },
      ])
    );
  }, [dispatch]);
  useEffect(() => {
    if (data !== undefined) {
      setPartnerLeads(data.data);
    }
  }, [data]);
  return (
    <>
      <Box
        sx={{
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "5px",
        }}
      >
        <DataGrid
          // getCellClassName={(e) => "custom-cell"}
          // getRowClassName={(e) => "custom-header"}
          disableSelectionOnClick
          rows={PartnerLeads}
          getRowId={(row) => row._id}
          columns={columns}
          sx={{ cursor: "default" }}
          // onRowDoubleClick={(v) => navigate(`/vendors/view/${v?.row?._id}`)}
          components={{
            Toolbar: GridToolbar,
            NoRowsOverlay: () => {
              return (
                <Stack
                  height="100%"
                  alignItems="center"
                  justifyContent="center"
                >
                  {isLoading ? (
                    <Loader />
                  ) : !isLoading && data == undefined ? (
                    " Something went wrong"
                  ) : !data?.data[0] ? (
                    " No rows"
                  ) : null}
                </Stack>
              );
            },
          }}
          // pageSize={10} rowsPerPageOptions={[10,20,30]}
          // checkboxSelection
        />
        {dId && (
          <DeleteDialogue
            dId={dId}
            setdId={setdId}
            handleDelete={handleDelete}
            isLoading={deleteLoading}
          />
        )}
        {
          view&&<ViewPartner view={view} setView={setView}/>
        }
      </Box>
    </>
  );
};
