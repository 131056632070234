import { combineReducers, configureStore } from "@reduxjs/toolkit"
import { mainApi,mainApiForNonToken } from "../api/mainApi"
import themeReducer from "../slice/themeSlice"
import authReducer from "../slice/authSlice"
import otherReducer from "../slice/otherSlice"
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from "redux-persist"
import storage from "redux-persist/lib/storage"
import vendorSlice from "../slice/vendorSlice"
const persistConfig = {
	key: "root",
	version: 1,
	storage,
	blacklist: [mainApi.reducerPath,mainApiForNonToken.reducerPath],
}
const rootReducer = combineReducers({
	[mainApi.reducerPath]: mainApi.reducer,
	[mainApiForNonToken.reducerPath]:mainApiForNonToken.reducer,
	theme: themeReducer,
	auth: authReducer,
	others: otherReducer,
	vendor: vendorSlice
})
const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
	reducer: persistedReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
			},
		}).concat(mainApi.middleware).concat(mainApiForNonToken.middleware),
})

export const persistor = persistStore(store)
