import { Box, FormLabel, TextField, Typography } from "@mui/material";
import React from "react";
import { useState } from "react";
import { useRef } from "react";

function ExpiryDate({ handleChange, errors, touched, values,...others }) {
  const inputRef = useRef(null);
  const [value, setValue] = useState();
  const handleKeyUp = (v) => {
    if (v.toString().length == 2) {
      inputRef.current.focus();
    }
  };
  const style = {
    border: "none",
    height: "10px",
    width: "40%",
  };
  return (
    <>
      {/* <div style={{border:'1px solid black',height:'100%'}}> */}
      <FormLabel display="block" id="demo-controlled-radio-buttons-group">
        Expiry Date
      </FormLabel>
      <Box>
        <TextField
          error={touched["exp_month"] && Boolean(errors["exp_month"])}
          name="exp_month"
          style={style}
          placeholder="MM"
          type="text"
          defaultValue={values.exp_month}
          className="in"
          helperText={touched["exp_month"] && errors["exp_month"]}
          onKeyUp={(e) => handleKeyUp(e.target.value)}
          variant="standard"
          onChange={handleChange}
          onKeyPress={(evt) => {
            if (
              (evt.which != 8 && evt.which != 0 && evt.which < 48) ||
              evt.which > 57
            ) {
              evt.preventDefault();
            }
          }}
          InputProps={{ inputProps: { maxLength: 2 } }}
          {...others}
        />
        <Typography
          variant="span"
          component="span"
          fontSize={23}
          fontWeight={50}
          sx={{ mb: 20 }}
        >
          {" "}
          /{""}
        </Typography>
        <TextField
          style={style}
          helperText={touched["exp_year"] && errors["exp_year"]}
          error={touched["exp_year"] && Boolean(errors["exp_year"])}
          type="text"
          name="exp_year"
          placeholder="YY"
          defaultValue={values.exp_year}
          variant="standard"
          InputProps={{ inputProps: { min: 1, maxLength: 2 } }}
          className="in"
          inputRef={inputRef}
          onChange={handleChange}
          onKeyPress={(evt) => {
            if (
              (evt.which != 8 && evt.which != 0 && evt.which < 48) ||
              evt.which > 57
            ) {
              evt.preventDefault();
            }
          }}
          {...others}
        />
      </Box>
      {/* </div> */}
    </>
  );
}

export default ExpiryDate;
