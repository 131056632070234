import { createSlice } from "@reduxjs/toolkit"
import { toast } from "react-toastify"

const authSlice = createSlice({
    name: "auth",
    initialState: {
        user: null,
        token: null,
        isAccess: false,
        message: "Please Login!!",
        GetUser:null
        
    },
    reducers: {
        setUser: (state, actions) => {
            const { token, ...user } = actions.payload
            state.user = user
            state.token = token
            state.isAccess = true
        },
        logOut: (state, actions) => {
            state.user = null
            state.token = null
            state.isAccess = false
            localStorage.clear()
        },
        error: (state) => {
            toast.error(state.message)
        },
        setGetUser:(state,action)=>{
            state.GetUser=action.payload
        }
    },
})

export const { setUser, logOut, error ,setGetUser} = authSlice.actions

export default authSlice.reducer
