import { lazy, Suspense, useEffect } from "react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import { useMode } from "./global/theme";
import Loader from "./components/loader/Loader";
import AddEditCard from "./views/payments/vendor_card/AddEditCard";
import FeedBack from "./views/feedback/FeedBack";
import { toast } from "react-toastify";
import { error } from "./features/slice/authSlice";
import ErrorPage from "./components/errorPage/ErrorPage";
import Subscribtion from "./views/subscribtion/Subscribtion";
import AddPackage from "./views/subscribtion/addPackage";
import PaymentMethod from "./views/paymentMethods/PaymentMethod";
import Twilio from "./views/settings/twillio/Twilio";
import Tiwlio from "./views/settings/twillio/Twilio";
import StripeConfig from "./views/settings/stripe/StripeConfig";
import FeedBackLists from "./views/feedback/FeedBackLists";
import MenuList from "./views/Menu/MenuList";
import { PartnerLeads } from "./views/PartnerLeads/PartnerLeads";
import { OnlineForm } from "./views/onlineForm/onlineForm";

//Components
const Login = lazy(() => import("./global/Login"));
const Layouts = lazy(() => import("./global/Layouts"));
const Dashboard = lazy(() => import("./views/dashboard/Dashboard"));
const Chats = lazy(() => import("./views/chats/Chats"));
const CustomerPayments = lazy(() =>
  import("./views/payments/customer_payments/CustomerPayments")
);
const VendorPayments = lazy(() =>
  import("./views/payments/vendor_payments/VendorPayments")
);
const ViewVendor = lazy(() => import("./views/vendors/ViewVendor"));
const CustomerList = lazy(() => import("./views/chats/CustomerList"));
const VendorList = lazy(() => import("./views/chats/VendorList"));
const Vendors = lazy(() => import("./views/vendors/Vendors"));
const EditVendor = lazy(() => import("./views/vendors/EditVendor"));
const AddEdit = lazy(() => import("./views/vendors/AddEdit"));

const LogOut = () => {
  const dispatch = useDispatch();
  dispatch(error());
  return <Navigate to="/login" />;
};

function App() {
  const [theme] = useMode();
  const access = useSelector((state) => state.auth.isAccess);
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className="app">
        <Suspense fallback={<Loader routeLoader={true} />}>
          <Routes>
            {!access ? (
              <Route path="/">
                <Route index element={<Login />} />
                <Route path="login" element={<Login />} />
                <Route path="*" element={<Navigate to="/login" />} />
                <Route path="/onlineform" element={<OnlineForm/>} />
              </Route>
            ) : (<>
              <Route path="/onlineform" element={<OnlineForm/>} />
              <Route element={<Layouts />}>
                <Route exact path="/" element={<Navigate to="/dashboard" />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/vendors" element={<Vendors />} />
                <Route path="/vendors/viewcard/" element={<Vendors />} />
                <Route path="/vendors/viewcard/addcard" element={<Vendors />} />
                <Route path="/vendors/addcard" element={<Vendors />} />
                <Route path="/vendors/add/:partner_id?" element={<AddEdit />} />
                <Route path="/vendors/view/:id" element={<ViewVendor />} />
                <Route path="/vendors/edit/:id" element={<EditVendor />} />
                <Route
                  exact
                  path="/chats/vendor/:vendorId?/:vendorName?"
                  element={<CustomerList />}
                />
                <Route
                  exact
                  path={`/chats/vendor/:vendorId/customer/:customerId`}
                  element={<Chats />}
                />
                <Route path="/chats/*" element={<VendorList />} />
                <Route exact path="/chats/vendors/:page?" element={<VendorList />} />
                {/* <Route exact path="/chats/vendors" element={<VendorList />} /> */}
                <Route path="/vendor_billing" element={<VendorPayments />} />
                <Route path="/orders" element={<CustomerPayments />} />
                <Route path="/feedback" element={<FeedBackLists />} />
                <Route path="/feedback/:vendorId" element={<FeedBack />} />
                <Route path="/subscription" element={<Subscribtion />} />
                <Route
                  path="/subscription/addpackage/:id?"
                  element={<AddPackage />}
                />
                <Route
                  path="/subscription/edit/package/:id?"
                  element={<AddPackage />}
                />
                <Route path="/payment_management" element={<PaymentMethod />} />
                <Route
                  path="/payment_management/addcard/:company_name"
                  element={<PaymentMethod />}
                />
                <Route path="/twilio" element={<Tiwlio />} />
                <Route path="/stripe_account" element={<StripeConfig />} />
                <Route path="/vendors/menu/:vendor_id?" element={<MenuList />} />
                <Route path="/partner_leads" element={<PartnerLeads />} />
                {/* <Route path="*" element={<ErrorPage />} /> */}
              </Route></>
            )}
          </Routes>
        </Suspense>
      </div>
    </ThemeProvider>
  );
}

export default App;
