import { mainApi, mainApiForNonToken } from "./mainApi";

export const PartnerLeadsApi = mainApiForNonToken.injectEndpoints({
  endpoints: (builder) => ({
    //CREATE PARTNER LEADS
    CreatePartner: builder.mutation({
      query: (formData) => ({
        url: `createpartnerleads`,
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["partners"],
    }),
  }),
});
export const PartnerLeadsApiAdminControle = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    //GET PARTNER LIST
    GetPartner: builder.query({
      query: (id) => `getpartnerleads?id=${id}`,
      providesTags: ["partners"],
    }),
    //GET PARTNER LIST BY ID
    GetPartnerById: builder.query({
      query: (id) => `getpartnerleads?id=${id}`,
      providesTags: ["partners"],
    }),

    //UPDATEPARTNER LEADS
    UpdatePartner: builder.mutation({
      query: (formData) => ({
        url: `/updatepartnerleads`,
        method: "PUT",
        body: formData,
      }),
      invalidatesTags: ["partners"],
    }),
    //DELETE PARTNERLEADS
    DeletePartner: builder.mutation({
      query: (id) => ({
        url: `deletepartner`,
        method: "DELETE",
        body: id,
      }),
      invalidatesTags: ["partners"],
    }),
    //DOWNLOAD PARTNER
    DownloadPartner: builder.mutation({
      query: (id) => ({
        url: `downloadpartner`,
        method: "POST",
        body: id,
        responseHandler: async (res) => {
          if (res.status === 200) {
            return await res.blob();
          } else {
            return await res.text();
          }
        },
      }),
      // invalidatesTags: ["partners"],
    }),
  }),
});

export const { useCreatePartnerMutation } = PartnerLeadsApi;

export const {
  useGetPartnerQuery,

  useDeletePartnerMutation,
  useUpdatePartnerMutation,
  useDownloadPartnerMutation,
} = PartnerLeadsApiAdminControle;
