import { mainApi } from "./mainApi";


export const CardApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({

    //LIST CARDS
    ListCards:builder.query({
      query:()=>`/listcard `,
      providesTags:['cards']
    }),
    //VIEW CARD
    ViewCardById: builder.query({
      query: (id) => `viewcard/${id}`,
      providesTags: ["card"],
    }),
    //ADD CARD
    AddCard: builder.mutation({
      query: (CardData) => ({
        url: `/addcard`,
        method: "POST",
        body: CardData,
      }),
      invalidatesTags: ["Vendor", "card","cards"],
    }),

    //DELETE CARD
    CardDelete: builder.mutation({
      query: ({ v_id, c_id }) => ({
        url: `/erasecard/${v_id}/${c_id}`,
        method: "PUT",
      }),
      invalidatesTags: ["card","Vendor","cards"],
    }),
    //UPDATE PACKAGE
    EditCard: builder.mutation({
      query: (editData) => ({
        url: `/updatecard`,
        method: "POST",
        body: editData,
      }), 
      invalidatesTags: ["card","Vendor","cards"],
    }),
  }),
});

export const {
  useAddCardMutation,
  useViewCardByIdQuery,
  useCardDeleteMutation,
  useEditCardMutation,
  useListCardsQuery
} = CardApi;
