import { mainApi } from "./mainApi";

//Notification List
export const feedbackApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    //Vendor with all feedback
    vendorAllFeedBack: builder.query({
      query: () => `vendor?include4=feedback`,
    }),
    //Vendor with unseen feedback
    vendorUnseenFeedBack: builder.query({
      query: () => `vendor?include4=feedback&status=unseen`,
    }),
    //Notification Unseen
    notificationUnseen: builder.query({
      query: () => `feedback?status=unseen`,
      providesTags: ["Feedback"],
    }),
    //FeedBack by Id
    feedBackById: builder.query({
      query: (id) => {
        return `feedback/${id}`;
      },
      providesTags: ["Feedback"],
    }),
    //All Feedback
    allFeedBack: builder.query({
      query: (id) => {
        return `feedback?view=table`;
      },

      providesTags: ["Feedback"],
    }),
    //Feedback status change
    feedbackStatusChange: builder.mutation({
      query: ({ id, data }) => ({
        url: `feedback/status/${id}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Feedback"],
    }),
    //DELETE FEEDBACK BY ID
    DeleteFeedBack: builder.mutation({
      query: (id) => ({
        url: `feedback/${id}`,
        method: "PUT",
      }),
      invalidatesTags: ["Feedback"],
    }),
  }),
});

export const {
  useVendorAllFeedBackQuery,
  useVendorUnseenFeedBackQuery,
  useNotificationUnseenQuery,
  useFeedBackByIdQuery,
  useAllFeedBackQuery,
  useFeedbackStatusChangeMutation,
  useDeleteFeedBackMutation
} = feedbackApi;
