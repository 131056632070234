import { CircularProgress } from "@mui/material"
import { Box } from "@mui/material"
import React from "react"

const Loader = ({ routeLoader }) => {
	return (
		<Box
			sx={{
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				height: routeLoader ? "100vh" : "100%",
				width: routeLoader ? "100vw" : "100%",
				padding: "0px",
				margin: "0px",
			}}>
			<CircularProgress color="primary"></CircularProgress>
		</Box>
	)
}

export default Loader
