import { Stack, Box, Paper, Typography, Divider } from "@mui/material"
import dayjs from "dayjs"
import relativeTime from "dayjs/plugin/relativeTime"
import { useNavigate, useParams } from "react-router-dom"

dayjs.extend(relativeTime)

export const VendorChats = ({ data, colors, length, vendorId }) => {
    const navigate = useNavigate()
    const { customerId } = useParams()
    return (
        <Stack
            direction={"row"}
            alignItems="center"
            justifyContent={"space-around"}
            //spacing={2}
            onClick={() => {
                navigate(`/chats/vendor/${vendorId}/customer/${data}`)
            }}
            p={1}
            sx={{
                width: "100%",
                cursor: "pointer",
                borderRadius: "10px 1px",
                color: customerId === data && "white",
                background: customerId === data && colors?.redAccent[100],
            }}>
            <Typography sx={{}}>{data?.toString()}</Typography>
        </Stack>
    )
}

export const AllChats = ({ align, data, colors, divide, demo }) => {
    return (
        <>
            <Box ref={demo}>
                {divide && (
                    <Divider
                        sx={{
                            paddingInline: "10%",
                        }}>
                        {dayjs(data?.dateSent).format("MM/DD/YYYY")}
                    </Divider>
                )}
            </Box>
            <Box
                sx={{
                    borderRadius: "5px",
                    display: "flex",
                    justifyContent: align,
                    // padding: "10px",
                    width: "100%",
                }}>
                <Paper
                    sx={{
                        // padding: "10px",
                        maxWidth: "80%",
                        background:
                            align === "start" ? "white" : colors.redAccent[100],
                        wordBreak: "break-word",
                        color: align === "start" ? "gray" : "white",
                        borderRadius:
                            align === "start"
                                ? "5px 5px 5px 1px"
                                : "5px 5px 1px 5px",
                    }}>
                    <Typography
                        sx={{
                            // padding: "10px 10px 0 10px",
                            padding: align === "end" ? "5px" : "5px",
                            minWidth: "50px",
                        }}>
                        {data.body.toString()}
                    </Typography>
                    {/* <Typography sx={{
                    fontSize : '10px',
                    textAlign : "end",
                    padding : "0 15px 0px 15px",
                }}>{dayjs(data?.dateUpdated)?.fromNow()?.toString() ?? ""}</Typography> */}
                    <Typography
                        sx={{
                            fontSize: "10px",
                            textAlign: align,
                            paddingInline: "5px"
                        }}>
                        {dayjs(data?.dateSent).format("hh:mm A")}
                    </Typography>
                </Paper>
            </Box>


        </>
    )
}

export const FeedBackVendor = ({ data, colors, handleFeedBack, handleBreadCrumb }) => {
    const navigate = useNavigate()
    const { vendorId } = useParams()
    return (
        <Stack
            direction={"row"}
            // alignItems="center"
            justifyContent={"space-around"}
            onClick={() => {
                navigate(`/feedback/${data._id}`)
                // handleFeedBack(vendorId)
                // handleBreadCrumb(data.company_name, data._id)
            }}
            //spacing={2}
            // onClick={() => {
            //     navigate(`/chats/vendor/${vendorId}/customer/${data}`)
            // }}
            p={1}
            sx={{

                cursor: "pointer",
                borderRadius: "10px 1px",
                display: 'flex',
                flexDirection: "column",

                // p: 1,
                color: vendorId === data._id ? "white" : "",
                background:
                    vendorId === data._id ? colors?.redAccent[100] : "",
            }}>
            <Typography sx={{
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
            }}>{data?.company_name?.toString()}</Typography>
            <Typography
                sx={{
                    maxWidth: "300px",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                }}>
                {data?.feedback[0]?.feedBack?.toString()}
            </Typography>
        </Stack>
    )
}



export const VendorFeedBacksChat = ({ align, data, colors, divide, demo }) => {
    return (
        <>
            <Box ref={demo}>
                {divide && (
                    <Divider
                        sx={{
                            paddingInline: "10%",
                        }}>
                        {dayjs(data?.feedBack_date).format("MM/DD/YYYY")}
                    </Divider>
                )}
            </Box>
            <Box
                sx={{
                    borderRadius: "5px",
                    display: "flex",
                    justifyContent: align,
                    padding: "10px 5px 10px 50px",
                    width: "100%",
                }}>
                <Paper
                    sx={{
                        // padding: "10px",
                        // maxWidth: "80%",
                        background:
                            align === "start" ? "white" : colors.redAccent[100],
                        wordBreak: "break-word",
                        color: align === "start" ? "gray" : "white",
                        borderRadius:
                            align === "start"
                                ? "5px 5px 5px 1px"
                                : "5px 5px 1px 5px",
                    }}>
                    <Typography
                        sx={{
                            // padding: "10px 10px 0 10px",
                            padding: align === "end" ? "5px" : "5px",
                            minWidth: "50px",
                        }}>
                        {data?.feedBack?.toString()}
                    </Typography>
                    {/* <Typography sx={{
                    fontSize : '10px',
                    textAlign : "end",
                    padding : "0 15px 0px 15px",
                }}>{dayjs(data?.dateUpdated)?.fromNow()?.toString() ?? ""}</Typography> */}
                    <Typography
                        sx={{
                            fontSize: "10px",
                            textAlign: align,
                            paddingInline: "5px"
                            // padding: align === "start" ? "5px 5px 5px 1px" : "5px 5px 1px 5px",
                        }}>
                        {dayjs(data?.feedBack_date).format("hh:mm A")}
                    </Typography>
                </Paper>
            </Box>

        </>
    )
}