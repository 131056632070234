import { mainApi } from "./mainApi";

//Auth Api
export const authApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    // Login
    adminLogin: builder.mutation({
      query: (LoginData) => ({
        url: "auth/login",
        body: LoginData,
        method: "POST",
      }),
    }),
    //Logout
    adminLogout: builder.mutation({
      query: () => ({
        url: "auth/logout",
        method: "POST",
      }),
    }),
    //GET USER
    GetUser: builder.query({
      query: (id) => ({
        url: `/auth/me/${id}`,
        providesTags: ["user"],
      }),
    }),
  }),
});

export const {
  useAdminLoginMutation,
  useAdminLogoutMutation,
  useGetUserQuery,
} = authApi;
