import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  CircularProgress,
  colors,
  Divider,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { maxHeight } from "@mui/system";
import { Form, Formik } from "formik";
import * as yup from "yup";
import React, { useState } from "react";
import { InputField } from "../../../components/input/InputField";
import { cc } from "../../../global/country";
import { useVendorListQuery } from "../../../features/api/vendorApi";
import { useEffect } from "react";
import { setBreadCrumb } from "../../../features/slice/otherSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  useAddTwilioConfigMutation,
  useTwilioViewQuery,
} from "../../../features/api/twilio";
import { toast } from "react-toastify";
import { useGetUserQuery } from "../../../features/api/authApi";
import { setGetUser } from "../../../features/slice/authSlice";
import toastError from "../../../components/toasts/toast";
export default function Tiwlio() {
  const [vendor, setVEndor] = useState();
  const { user, GetUser } = useSelector((state) => state.auth);
  console.log("GETUSER", GetUser);
  //API
  const { data } = useVendorListQuery();
  const dispatch = useDispatch();
  const [initialValues] = useState([
    // "admin_id",
    "username",
    "password",
    "country_code",
    "from_number",
  ]);
  const [flag, setFlag] = useState([{ country_name: "", country_code: "" }]);
  const [country, setCountry] = useState();
  const [vendorId, setVendorId] = useState();

  //API
  const [AddTwilioConfig, { isLoading:twilioLoading }] = useAddTwilioConfigMutation();
  const {
    data: authData,
    isLoading: authLoading,
    refetch,
  } = useGetUserQuery(user?.admin._doc._id);
  const validationSchema = yup.object().shape({
    username: yup
      .string("Enter your Username")
      .required("Firstname is required"),
    password: yup
      .string("Enter your Password")
      .min(8, "Password is too short - should be 8 chars minimum.")
      // .matches(/[a-zA-Z]/, 'Password can only contain Latin letters.')
      .required("Password is required"),
    from_number: yup
      .number("Enter Phone Number")
      .min(1000000000, "Enter Valid Number")
      .max(9999999999, "Enter Valid Number")
      .typeError("Enter Valid Number")
      .required("Phone Number is Required"),
  });
  useEffect(() => {
    if (authData !== undefined) {
      Promise.all(dispatch(setGetUser(authData.data.admin)));
    }
  }, [authData]);
  // useEffect(() => {
  //   refetch(user?.admin._doc._id);
  // }, [user, refetch]);
  useEffect(() => {
    if (data !== undefined) {
      setVEndor(data?.data);
    }
  }, [data]);
  useEffect(() => {
    dispatch(
      setBreadCrumb([
        {
          name: "TWILIO",
          path: "/twilio",
        },
      ])
    );
  }, [dispatch, window.location]);
  return (
    <>
      <Grid
        item
        sx={{ background: "white", height: "80%" }}
        justifyContent="center"
        alignItems="center"
        md={8}
      >
        <Formik
          initialValues={{
            admin_id: GetUser?._id,
            username: GetUser?.twilioConfig?.username,
            password: GetUser?.twilioConfig?.password,
            country_code: "+1",
            from_number: GetUser?.twilioConfig?.from_number,
            sms_enabled: GetUser?.twilioConfig?.sms_enabled
              ? GetUser?.twilioConfig?.sms_enabled
              : "Yes",
          }}
          validationSchema={validationSchema}
          onSubmit={(va) => {
            console.log(user?._id);
            console.log(va);
            AddTwilioConfig(va)
              .unwrap()
              .then((res) => {
                refetch(user?.admin._doc._id);
                toast.success("Twilio Config successfully");
                // navigate("/vendors");
              })
              .catch((err) => {
                console.log("ERRROr", err);
                toastError(err.data.error?err.data.error:"Server Error !")
              });
            console.log(va);
          }}
          maxWidth="md"
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "50px",
          }}
        >
          {({
            errors,
            touched,
            handleChange,
            setFieldValue,
            getFieldMeta,
            values,
          }) => (
            <Form
              style={{
                display: "flex",
                gap: "20px",
                flexDirection: "column",
              }}
              onChange={(values) => {}}
            >
              <Box padding={6}>
                <Typography variant="h3" component="h3" display={"inline"}>
                  SMS
                </Typography>

                {/* <Typography
                  variant="h3"
                  component="h3"
                  display={"inline"}
                  sx={{ float: "right" }}
                  color="red"
                >
                  Test
                </Typography> */}

                <Grid
                  container
                  rowSpacing={5}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  marginTop={3}
                >
                  {initialValues.map((item, index) => {
                    if (item == "admin_id") {
                      console.log("VAL", values);
                      return (
                        <>
                          <Grid item xl={12} lg={12} sm={12} xs={12}>
                            <Autocomplete
                              disablePortal
                              id="VENDOR NAME / COMPANY NAME"
                              name={item}
                              options={vendor ? vendor : [""]}
                              getOptionLabel={(o) =>
                                o.firstname + "/" + o.company_name
                              }
                              onChange={(e, v) => {
                                setFieldValue(item, v._id);
                              }}
                              error={errors}
                              setFieldValue={setFieldValue}
                              getFieldMeta={getFieldMeta}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="ADMIN NAME/COMPANY NAME"
                                />
                              )}
                            />
                          </Grid>
                        </>
                      );
                    }
                    if (item == "country_code") {
                      return (
                        <>
                          <Grid item xl={6} lg={6} sm={6} xs={6}>
                            <Autocomplete
                              fullWidth
                              disabled={true}
                              disablePortal
                              name={"country_code"}
                              id="country_code"
                              options={cc}
                              defaultValue={{
                                name: values?.country_code
                                  ? cc.filter(
                                      (c) => c.dial_code == values?.country_code
                                    )[1].name
                                  : "United States",
                                dial_code: values.country_code
                                  ? values.country_code
                                  : +1,
                                code: values?.country_code
                                  ? cc.filter(
                                      (c) => c.dial_code == values?.country_code
                                    )[1].code
                                  : "+1",
                              }}
                              // sx={{ width: "10%" }}
                              getOptionLabel={(o) => o.dial_code + " " + o.name}
                              onChange={(e, v) => {
                                setFieldValue("country_code", v.dial_code);
                                setCountry(v);
                              }}
                              error={errors}
                              setFieldValue={setFieldValue}
                              getFieldMeta={getFieldMeta}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="SMS Gateway Country Code"
                                  InputProps={{
                                    ...params.InputProps,
                                    startAdornment: (
                                      <img
                                        src={`https://flagcdn.com/16x12/${
                                          country
                                            ? country.code.toLowerCase()
                                            : values?.country_code
                                            ? cc
                                                .filter(
                                                  (c) =>
                                                    c.dial_code ==
                                                    values?.country_code
                                                )[1]
                                                .code.toLowerCase()
                                            : "us"
                                        }.png`}
                                      />
                                    ),
                                  }}
                                />
                              )}
                            />
                          </Grid>
                        </>
                      );
                    }
                    return (
                      <>
                        <InputField
                          key={index}
                          name={item}
                          label={
                            item == "username"
                              ? "SMS Gateway Username"
                              : item == "password"
                              ? "SMS Gateway Password"
                              : item == "from_number"
                              ? "SMS Gateway From Code"
                              : item
                          }
                          getFieldMeta={getFieldMeta}
                          errors={errors}
                          setFieldValue={setFieldValue}
                          touched={touched}
                          values={values}
                          onChange={handleChange}
                          fullWidth
                          // type={item === "from_number" ? "number" : null}

                          mdcol={6}
                        />
                      </>
                    );
                  })}{" "}
                  <Grid item>
                    <FormLabel
                      display="block"
                      id="demo-controlled-radio-buttons-group"
                    >
                      Is Sms Enabled
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="sms_enabled"
                      defaultValue={values.sms_enabled}
                      onChange={handleChange}
                    >
                      <FormControlLabel
                        value="Yes"
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="No"
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </Grid>
                  <Grid
                    container
                    justifyContent="center"
                    alignItems="flex-end"
                    marginTop={8}
                  >
                    <Grid item>
                      <LoadingButton
                        sx={{
                          padding: "12px",
                          paddingX: "30px",
                          color: "#ffff",
                        }}
                        loading={twilioLoading}
                        loadingPosition="center"
                        type="submit"
                        variant="contained"
                        color="primary"
                      >
                        <span>Save</span>
                      </LoadingButton>{" "}
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Form>
          )}
        </Formik>
      </Grid>
    </>
  );
}
