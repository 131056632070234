import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { error } from "../slice/authSlice";
import { Store } from "@mui/icons-material";
import { toast } from "react-toastify";

//Main Api
const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_MAIN_URL,
  prepareHeaders: (headers, { getState }) => {
    headers.set("ngrok-skip-browser-warning", "69420");
    const token = getState().auth.token;
    if (token) {
      headers.set("authorization", `Bearer ${token}`);
    }
    return headers;
  },
});
const baseQueryforNonToken = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_IMG_URL + "public/",
  prepareHeaders: (headers, { getState }) => {
    headers.set("ngrok-skip-browser-warning", "69420");
    return headers;
  },
});

// const baseQueryWithReauth = async (args, api, extraOptions) => {
//     const result = await baseQuery(args, api, extraOptions)
//     if (result?.meta?.response?.status === 401) {
//         api.dispatch(error())
//     }
// }
const basequeryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  console.log(result, "RESULT");
  if (result?.error?.status === 401) {
    console.log("first");
    localStorage.clear();
    toast.error("Session Expired")
    window.location.reload()
  }
  return result;
};

export const mainApi = createApi({
  reducerPath: "mainApi",
  baseQuery: basequeryWithReauth,
  tagTypes: [
    "User",
    "Vendor",
    "Chat",
    "Subscription",
    "Vendor_Payments",
    "Customer_Payments",
    "Dashboard",
    "Feedback",
    "card",
    "cards",
    "menu",
    "partners",
  ],
  endpoints: (builder) => ({}),
});
export const mainApiForNonToken = createApi({
  reducerPath: "mainApiForNonToken",
  baseQuery: baseQueryforNonToken,
  // tagTypes: ["partners"],
  endpoints: (builder) => ({}),
});

//Refersh token not finished
// const baseQuery = fetchBaseQuery({
//     baseUrl: process.env.REACT_APP_MAIN_URL,
//     prepareHeaders: (headers, { getState }) => {
//         // headers.set("ngrok-skip-browser-warning", "69420")
//         const token = getState().auth.token
//         if (token) {
//             headers.set('authorization', `Bearer ${token}`)
//         }
//         return headers
//     }
// })

// export const mainApi = createApi({
//     baseQuery,
//     tagTypes : ["User", "Vendor", 'Chat', 'Vendor_Payments', "Customer_Payments"],
//     endpoints : ()=>({})
// })
