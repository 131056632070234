import { mainApi } from "./mainApi";

export const TwilioApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    //Vendor List
    // TwilioView: builder.query({
    //   query: (id) => `/twilioconfig/${id}`,
    //   providesTags: ["twilio"],
    // }),
    //ADD TWILIO CONFIG
    AddStripeConfig: builder.mutation({
      query: (stripeData) => ({
        url: `/stripeconfig/createforadmin`,
        method: "POST",
        body: stripeData,
      }),
      invalidatesTags: ["twilio,user"],
    }),
  }),
});

export const { useAddStripeConfigMutation } = TwilioApi;
