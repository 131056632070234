import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Modal,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import "./partnerLeads.css";
//ICONS
import CloseIcon from "@mui/icons-material/Close";
import dayjs from "dayjs";
import { Download, Height, Label, Padding } from "@mui/icons-material";
//LOGO
import Logo from "../../assests/logo.png";
import { NavLink, useNavigate } from "react-router-dom";
import { useDownloadPartnerMutation } from "../../features/api/partnerLeads";
import { toast } from "react-toastify";
import fileDownload from "js-file-download";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: { sm: `calc(100% - ${drawerWidth}px)` },

  maxHeight: "90%",
  // width: "50%",
  // eight:"1000px",
  border: "2px solid white",
  boxShadow: 24,
  padding: 20,
  background: "white",
    overflow: "scroll",
};
export const ViewPartner = ({ view, setView }) => {
  //STATES
  //DECLARTIONS
  const navigate = useNavigate();
  const dispatch = useDispatch();
  //APIS
  const [DownloadPartner, { isLoading: isDownloading }] =
    useDownloadPartnerMutation();
  const handleDownload = (type,id) => {
    const de = toast.loading("Downloading...");
    DownloadPartner({partner_id:id})
      .unwrap()
      .then((res) => {
        console.log(res);
        toast.update(de, {
          render: "Downloaded",
          type: "success",
          isLoading: isDownloading,
          autoClose: 2000,
        });
        fileDownload(res, `${type}.pdf`);
      })
      .catch((err) => {
        toast.update(de, {
          render: err?.data?.error ?? "Form not download for this moment!!",
          type: "error",
          isLoading: isDownloading,
          autoClose: 2000,
        });
      });
  };

  return (
    <>
      <Modal
        open={view !== null}
        onClose={() => setView(null)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box style={style} sx={{width:{xs:"100%",md:"50%"}}}>
          {" "}
          {/* <Box>
            <IconButton sx={{ float: "right" }} onClick={() => setView(null)}>
              <CloseIcon />
            </IconButton>
          </Box> */}
          <Box
            sx={{
              display: "flex",
              paddingBottom: 3,
            }}
          >
            <img width={"20%"} src={Logo} alt="Logo" />{" "}
          </Box>
          <Box sx={{ height: "100%", maxHeight: "100%", overflow: "scroll" }}>
            <Box>
              <Grid
                container
                justifyContent={"space-between"}
                rowSpacing={1}
                columnSpacing={2}
              >
                {[]}
                <Grid item sm={6} md={6}>
                  <Typography
                    fontFamily={"revert"}
                    fontStyle={"oblique"}
                    variant="h5"
                    fontWeight={500}
                    marginBottom={3}
                  >
                    First Name :{" "}
                    <Typography variant="h5" component={"span"}>
                      {view?.firstname}
                    </Typography>
                  </Typography>

                  <Typography
                    fontFamily={"revert"}
                    fontStyle={"oblique"}
                    variant="h5"
                    fontWeight={500}
                    marginBottom={3}
                  >
                    Email :{" "}
                    <Typography variant="h5" component={"span"}>
                      {view?.email}
                    </Typography>
                  </Typography>
                  <Typography
                    fontFamily={"revert"}
                    fontStyle={"oblique"}
                    variant="h5"
                    fontWeight={500}
                    marginBottom={3}
                  >
                    Nature Of Business :{" "}
                    <Typography variant="h5" component={"span"}>
                      {view?.nature_of_business}
                    </Typography>
                  </Typography>
                  <Typography
                    fontFamily={"revert"}
                    fontStyle={"oblique"}
                    variant="h5"
                    fontWeight={500}
                    marginBottom={3}
                  >
                    Place Name :{" "}
                    <Typography variant="h5" component={"span"}>
                      {view?.place_name}
                    </Typography>
                  </Typography>
                  <Typography
                    fontFamily={"revert"}
                    fontStyle={"oblique"}
                    variant="h5"
                    fontWeight={500}
                    marginBottom={3}
                  >
                    Address :{" "}
                    <Typography variant="h5" component={"span"}>
                      {view?.address}
                    </Typography>
                  </Typography>
                  <Typography
                    fontFamily={"revert"}
                    fontStyle={"oblique"}
                    variant="h5"
                    fontWeight={500}
                    marginBottom={3}
                  >
                    City :{" "}
                    <Typography variant="h5" component={"span"}>
                      {view?.city}
                    </Typography>
                  </Typography>
                  <Typography
                    fontFamily={"revert"}
                    fontStyle={"oblique"}
                    variant="h5"
                    fontWeight={500}
                    marginBottom={3}
                  >
                    Zip Code :{" "}
                    <Typography variant="h5" component={"span"}>
                      {view?.zip_code}
                    </Typography>
                  </Typography>
                  <Typography
                    fontFamily={"revert"}
                    fontStyle={"oblique"}
                    variant="h5"
                    fontWeight={500}
                    marginBottom={3}
                  >
                    When :{" "}
                    <Typography variant="h5" component={"span"}>
                      {view?.when}
                    </Typography>
                  </Typography>
                </Grid>

                <Grid item sm={6} md={6}>
                  <Typography
                    fontFamily={"revert"}
                    fontStyle={"oblique"}
                    variant="h5"
                    fontWeight={500}
                    marginBottom={3}
                  >
                    Last Name :{" "}
                    <Typography variant="h5" component={"span"}>
                      {view?.lastname}
                    </Typography>
                  </Typography>
                  <Typography
                    fontFamily={"revert"}
                    fontStyle={"oblique"}
                    variant="h5"
                    fontWeight={500}
                    marginBottom={3}
                  >
                    Mobile Number :{" "}
                    <Typography variant="h5" component={"span"}>
                      {view?.mobile_number}
                    </Typography>
                  </Typography>

                  <Typography
                    fontFamily={"revert"}
                    fontStyle={"oblique"}
                    variant="h5"
                    fontWeight={500}
                    marginBottom={3}
                  >
                    Web Site :{" "}
                    <Typography variant="h6" component={"span"}>
                      {view?.web_site ? view?.web_site : "None"}
                    </Typography>
                  </Typography>
                  <Typography
                    fontFamily={"revert"}
                    fontStyle={"oblique"}
                    variant="h5"
                    fontWeight={500}
                    marginBottom={3}
                  >
                    Place Phone Number :{" "}
                    <Typography variant="h5" component={"span"}>
                      {view?.place_phone_number}
                    </Typography>
                  </Typography>
                  <Typography
                    fontFamily={"revert"}
                    fontStyle={"oblique"}
                    variant="h5"
                    fontWeight={500}
                    marginBottom={3}
                  >
                    Area :{" "}
                    <Typography variant="h6" component={"span"}>
                      {view?.area}
                    </Typography>
                  </Typography>
                  <Typography
                    fontFamily={"revert"}
                    fontStyle={"oblique"}
                    variant="h5"
                    fontWeight={500}
                    marginBottom={3}
                  >
                    State/Province :{" "}
                    <Typography variant="h6" component={"span"}>
                      {view?.state}
                    </Typography>
                  </Typography>
                  <Typography
                    fontFamily={"revert"}
                    fontStyle={"oblique"}
                    variant="h5"
                    fontWeight={500}
                    marginBottom={3}
                  >
                    Country :{" "}
                    <Typography variant="h6" component={"span"}>
                      {view?.state}
                    </Typography>
                  </Typography>
                </Grid>

                <Grid item md={12} sm={12}>
                  <Typography
                    fontFamily={"revert"}
                    fontStyle={"oblique"}
                    variant="h5"
                    fontWeight={500}
                    component={"span"}
                    marginBottom={3}
                  >
                    Message
                  </Typography>

                  <Typography
                    variant="h5"
                    maxHeight={100}
                    minWidth={100}
                    overflow={"scroll"}
                    className={"textover"}
                  >
                    {view?.message ? view?.message : "No Messages"}
                  </Typography>
                </Grid>
                <Box
                  mt={2}
                  display={"flex"}
                  flexDirection={"row"}
                  gap={3}
                  width={"100%"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Box>
                    <Button
                      variant="contained"
                      sx={{
                        color: "#ffff",
                      }}
                      color="primary"
                      onClick={() => setView(null)}
                    >
                      Close
                    </Button>
                  </Box>
                  <Box>
                    <Button
                      variant="contained"
                      sx={{
                        color: "#ffff",
                      }}
                      color="primary"
                      onClick={() => {
                        navigate(`/vendors/add/${view?._id}`);
                      }}
                    >
                      Convert to vendor
                    </Button>
                  </Box>
                  <Box>
                    {" "}
                    <Button
                    disabled={isDownloading}
                      variant="contained"
                      sx={{
                        color: "#ffff",
                      }}
                      color="primary"
                      onClick={()=>handleDownload(view?.firstname,view?._id)}
                    >
                      Download
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};
